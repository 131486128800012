<template>
  <div style="max-width: 1200px; margin: auto">
    <div v-if="isInvalidProduct" class="full-screen-alert d-flex justify-content-center align-items-center">
       <BAlert variant="warning" :model-value="true">
        {{ $t('signup.signup_to_paid.product_not_found') }}
      </BAlert>
    </div>
    <div class="px-3 px-sm-5 mx-md-5" v-else>
      <div class="d-flex py-2 my-2 flex-row justify-content-between align-items-center">
        <a href="">
          <div class="logo-lg"
            :style="{ backgroundImage: `var(--logo-light-default)`, width: '100px', height: '35px', backgroundSize: `contain`, backgroundRepeat: 'no-repeat' }">
          </div>
        </a>
        <h3 class="d-flex align-self-center">
          {{ $t('signup.signup_to_paid.title') }} {{ selectedProduct.name }}
        </h3>
        <b-dropdown variant="white" right class="ms-2" toggle-class="header-item">
          <template v-slot:button-content>
            <span v-b-tooltip.hover :title="$t('general.change_language')">
              <div class="d-flex align-items-center">
                <span :class="`flag flag-${locales($i18n.locale, 'language').code}`"
                  class="me-0 me-md-2 mt-1 mt-md-0"></span>
                <span class="d-none d-md-block">{{ $t(`locales.${$i18n.locale}`) }} <i
                    class="mdi mdi-chevron-down"></i></span>
              </div>
            </span>
          </template>
          <b-dropdown-item class="notify-item" @click='setLocale(l)' v-for='l in availableLocales' :key='l'>
            <div class="d-flex align-items-center">
              <span :class="`flag flag-${locales(l, 'language').code}`" class="me-2"></span>
              <span class="align-middle">{{ $t(`locales.${l}`) }}</span>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div v-if="!signupedSubscription">
        <div class="card" :class="{ 'loading': loading }">
          <form-wizard ref="formWizard" step-size="xs" color="#20a0cb">
            <tab-content :title="$t('signup.personal_details')">
              <PersonalDetails :setPreSignup="setPreSignup"
                :handlePersonalDetailsCompleted="handlePersonalDetailsCompleted" />
            </tab-content>
            <tab-content :title="$t('signup.select_your_plan')">
              <PlanSelection :handleAcceptedTerms="handleAcceptedTerms" :handleSelectedProduct="handleSelectedProduct"
                :handleSelectedPlans="handleSelectedPlans" :handleSelectedProductOpts="handleSelectedProductOpts" :handleSelectedTerm="handleSelectedTerm" />
            </tab-content>
            <tab-content :title="$t('signup.billing_details')">
              <div v-if="pre_signup">
                <BillingInfo ref="billingInfo" :handlePaymentMethodSaved="handlePaymentMethodSaved"
                  :pre_signup_id="pre_signup.id" :setStripeCustomerId="setStripeCustomerId" />
              </div>
              <div v-if="errors.length > 0">
                <BAlert variant="danger" show dismissible>
                  <ul>
                    <li v-for="error in errors" :key="error">{{ error }}</li>
                  </ul>
                </BAlert>
              </div>
            </tab-content>

            <template v-slot:footer="props">
              <div class="wizard-footer-left">
                <button class="btn" v-if="props.activeTabIndex > 0" :disabled="props.activeTabIndex === 0 || loading"
                  @click.native="props.prevTab()" :style="props.fillButtonStyle">
                  {{ $t('signup.signup_to_paid.previous') }}
                </button>
              </div>
              <div class="wizard-footer-right">
                <button class="btn" v-if="props.activeTabIndex > 0 && !props.isLastStep"
                  :disabled="nextButtonDisabled(props)" @click.native="props.nextTab()" :style="props.fillButtonStyle">
                  {{ $t('signup.signup_to_paid.next') }}
                </button>

                <button class="btn finish-button" v-if="props.isLastStep" @click="savePaymentAndFinishSignup" :style="props.fillButtonStyle">
                  {{ $t('signup.signup_to_paid.done') }}
                </button>
              </div>
            </template>
          </form-wizard>
        </div>
      </div>
      <div v-else>
        <UserOnboarding :subscriptionData="signupedSubscription" :userData="signupedUser" />
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import { FormWizard, TabContent } from 'vue3-form-wizard';
import PersonalDetails from './PersonalDetails.vue';
import axios from 'axios';
import BillingInfo from './BillingInfo.vue';
import PlanSelection from './PlanSelection.vue';
import UserOnboarding from './UserOnboarding.vue';
import 'vue3-form-wizard/dist/style.css';
import DataUtils from "@/js/data-utils";
import Utils from "@/js/utils";

export default {
  components: {
    FormWizard,
    TabContent,
    PersonalDetails,
    BillingInfo,
    PlanSelection,
    UserOnboarding,
  },
  data() {
    return {
      availableLocales: Utils.locales,
      loading: false,
      pre_signup: ref(null),
      stripe_customer_id: ref(null),
      acceptedTerms: ref(false),
      personalDetailsCompleted: ref(false),
      personalDetails: ref({}),
      selectedPlans: ref([]),
      selectedProduct: ref({}),
      selectedProductOpts: ref([]),
      paymentMethodSaved: ref(false),
      signupedSubscription: ref(null),
      signupedUser: ref(null),
      errors: ref([]),
      isInvalidProduct: false,
    };
  },
  async created() {
    this.$i18n.locale = await this.$store.dispatch('locale/fetch')
    if (!this.availableLocales.includes(this.$i18n.locale)) this.setLocale('en');
  },
  methods: {
    ...DataUtils,
    setLocale(locale) {
      this.$store.commit('locale/saveLocale', locale)
      this.$i18n.locale = locale
    },
    nextButtonDisabled(props) {
      return (
        (props.activeTabIndex === 0 && !this.personalDetailsCompleted) ||
        (props.activeTabIndex === 1 && (!this.acceptedTerms || this.selectedPlans.length === 0)) ||
        this.loading
      );
    },
    setStripeCustomerId(stripe_customer_id) {
      this.stripe_customer_id = stripe_customer_id;
    },
    setPreSignup(pre_signup) {
      this.pre_signup = pre_signup;
    },
    handleAcceptedTerms(accepted) {
      this.acceptedTerms = accepted;
    },
    handleSelectedTerm(term) {
      this.selectedTerm = term;
    },
    handlePersonalDetailsCompleted(personalDetails) {
      this.personalDetailsCompleted = true;
      this.personalDetails = personalDetails;
      this.$refs.formWizard.nextTab();
    },
    handlePaymentMethodSaved() {
      this.paymentMethodSaved = true;
      this.finishSignupToPaid();
    },
    handleSelectedPlans(plans) {
      this.selectedPlans = plans;
    },
    handleSelectedProductOpts(opts) {
      this.selectedProductOpts = opts;
    },
    handleSelectedProduct(product) {
      this.selectedProduct = product;
      this.isInvalidProduct = !product || Object.keys(product).length === 0;
    },
    async savePaymentAndFinishSignup() {
      try {
        await this.$refs.billingInfo.handleSubmit();
      } catch (error) {
        this.errors = error.response.data.error;
      }
    },
    async finishSignupToPaid() {
      this.loading = true;
      const subscribed_product = {
        product_id: this.selectedProduct.id,
        slug: this.selectedProduct.slug,
        specs: this.selectedProduct.specs,
        user_opts: {
          list: this.selectedProductOpts
        },
        selection: {
          features: this.selectedPlans.map(plan => plan.slug)
        },
        vat_number: this.personalDetails.vat_number,
        term: this.selectedTerm,
      };
      try {
        const response = await axios.post(`/v3/subscription_products/${this.selectedProduct.id}/signup`, {
          customer_id: this.stripe_customer_id,
          subscribed_product,
        });
        this.signupedSubscription = response.data.subscription;
        this.signupedUser = response.data.user;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.card.loading {
  opacity: 0.5;
}
.full-screen-alert {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9); /* Optional background overlay */
  z-index: 9999; /* Ensures it overlays everything else */
}
</style>
