<script>
import axios         from "axios";
import FeedbackUtils from "@/js/feedback-utils";
import Utils         from "@/js/utils";
import RepUtils      from "@/js/reputation-utils";
import Swal          from "sweetalert2";
import Bowser        from "bowser";
import DataUtils     from "@/js/data-utils";
import dayjs         from "dayjs";
import Multiselect   from "vue-multiselect";
import HelpSidebar   from "@/components/help-sidebar";
import locales       from "@/data/locales";
import { debounce }  from "lodash";

export default {
  data() {
    return {
      loading: false,
      additionalInfo: [
        { field: "reviewer_name", i18nPath: "name" },
        { field: "travel_composition_name", i18nPath: "travel_composition" },
        { field: "creation_date", i18nPath: "date" },
        { field: "package", i18nPath: "package" },
        { field: "helpful_votes", i18nPath: "helpful_votes" },
        { field: "price", i18nPath: "price" },
        { field: "room_name", i18nPath: "room_name" },
        { field: "reviewer_country", i18nPath: "country" },
        { field: "source_name", i18nPath: "source" },
        { field: "language_name_translated", i18nPath: "language" },
      ],
      reply: "",
      note: "",
      emailUsers: true,
      modals: { reply: false, note: false, ta: false },
      polaritiesToShow: ["positive", /*"neutral",*/ "negative"],
      polarityClass: {
        positive: { icon: "fa-smile text-success", tag: "bg-success"   },
        neutral:  { icon: "fa-meh text-secondary", tag: "bg-secondary" },
        negative: { icon: "fa-frown text-danger",  tag: "bg-danger"    }
      },
      showSubratings: false,
      showOffcanvas: false,
      promptParams: {},
      extensionSettings: {},
      generatedResponse: {
        tonality: "professional",
        customTonality: "",
        language: {
          use_review_language: false,
          customLanguage: this.$i18n.locale
        },
        mentionPropertyName: {
          enabled: true,
          value: "",
        },
        signName: {
          enabled: true,
          value: this.user.name,
        },
        limitWords: {
          enabled: true,
          value: 120,
        },
        wordsToMention: {
          enabled: true,
          selected: [],
          options: []
        },
        savedResponses: {
          value: null,
          options: []
        }
      },
    };
  },
  props: ["review", "reviewIndex", "cache", "params", "translations", "permittedReply", "sources", "user"],
  components: { Multiselect, HelpSidebar },
  async created() {
    if (this.review) {
      if (this.showAIFunctionality) this.getCompanyAlias();
      this.mergeSentiment();
      this.review.notes?.sort((a, b) => dayjs(b.date).diff(dayjs(a.date)));
      this.review.reviewer_name = this.review.reviewer.name;
      this.review.creation_date = dayjs(this.review.published_date).format("DD MMM YYYY");
      this.review.reviewer_country = this.review.reviewer.country.name;
      if (this.review.language) this.review.language_name_translated = this.locales(this.review.language).name;
      if (this.review.source) this.review.source_name = this.sources[this.review.source].friendly_name;
      if (this.review.travel_composition) this.review.travel_composition_name = this.$t(`travel_composition.${this.review.travel_composition}`);

      const managerResponse = this.review.comments?.find(c => c.kind == "manager")?.comment || ""
      this.reply = managerResponse;
      if (managerResponse.length) {
        const obj = { name: this.$t("daily_operations.reviews.comment_type.manager"), value: managerResponse };
        this.generatedResponse.savedResponses.value = obj;
        this.generatedResponse.savedResponses.options.push(obj)
      }
    }
  },

  methods: {
    ...DataUtils,
    ...FeedbackUtils,
    ...RepUtils,
    sourceIcon: Utils.sources.iconUrl,
    async getTranslation() {
      this.translations[this.$i18n.locale] ||= {};
      if (this.translations[this.$i18n.locale][`review_${this.review.id}`]) {
        this.cache[this.params.page][this.reviewIndex].translated = true;
        return;
      }
      this.translations[this.$i18n.locale][`review_${this.review.id}`] = {};
      this.loading = true;
      let payload = {
        data: { ...(this.review.title?.length) && { title: this.review.title } },
        locale: this.$i18n.locale
      }
      this.review.comments?.forEach(comment => {
        if (comment.kind != "manager") payload.data[`comment_${comment.kind}`] = comment.comment;
      });

      let response = await axios.post("/v3/translate", payload);
      if (!this.cache[this.params.page][this.reviewIndex].language) this.cache[this.params.page][this.reviewIndex].language = response.language;
      this.cache[this.params.page][this.reviewIndex].translatedFrom = response.data.language;
      response = response.data.data;

      if (this.review.title?.length) this.translations[this.$i18n.locale][this.review.title] = this.DOMParser(response.title?.[0]);
      this.review.comments?.forEach(comment => {
        if (comment.kind != "manager") {
          this.translations[this.$i18n.locale][`review_${this.review.id}`][`comment_${comment.kind}`] = this.DOMParser(response[`comment_${comment.kind}`]?.[0]);
        }
      });

      this.cache[this.params.page][this.reviewIndex].translated = true;
      this.loading = false;
      this.$forceUpdate();
    },
    async getCompanyAlias() {
      if (!this.review.company?.id) return;
      let res = await axios.get(`/v3/companies/${this.review.company.id}/alias`);
      this.generatedResponse.mentionPropertyName.value = res.data.data.alias || this.review.company.name;
    },
    async getExtensionSettings() {
      this.extensionSettings = null;
      return this.$store.dispatch("users/extension_settings").then(s => {
        this.extensionSettings = Utils.deepClone(s);
        if (this.extensionSettings.tonality) {
          if (!/professional|friendly/.test(this.extensionSettings.tonality)) {
            this.generatedResponse.customTonality = this.extensionSettings.tonality;
            this.generatedResponse.tonality = null;
          }
          else this.generatedResponse.tonality = this.extensionSettings.tonality;
        }
        if (this.extensionSettings.language) {
          if (this.extensionSettings.language == this.review.language_name) {
            this.generatedResponse.language.use_review_language = true;
            this.generatedResponse.language.customLanguage = null;
          } else {
            this.generatedResponse.language.use_review_language = false;
            this.generatedResponse.language.customLanguage = locales[this.extensionSettings.language];
          }
        }
        this.generatedResponse.wordsToMention = { ...this.generatedResponse.wordsToMention, ...(this.extensionSettings.words_to_mention || {}) };
        this.generatedResponse.savedResponses.options = Utils.deepClone(this.extensionSettings.saved_responses || []);
      });
    },
    async updateCompanyAlias() {
      try {
        let response = await Swal.fire({
          title: this.$t("general.are_you_sure"),
          html:  this.$t("daily_operations.reviews.save_alias_warning"),
          icon: "question",
          showCancelButton:  true,
          showConfirmButton: true,
          allowOutsideClick: false
        });
        if (!response.isConfirmed) return;
        await axios.post(`/v3/companies/${this.review.company.id}/alias`, { alias: this.generatedResponse.mentionPropertyName.value });
      } catch (err) {
        this.error(err)
      }
    },
    async addWordToMention(val) {
      this.generatedResponse.wordsToMention.options  = [...this.generatedResponse.wordsToMention.options, val];
      this.generatedResponse.wordsToMention.selected = [...this.generatedResponse.wordsToMention.selected, val];
      this.updateWordSelection();
    },
    removeWordsToMention(ev, val) {
      ev.stopPropagation();
      this.generatedResponse.wordsToMention.options  = this.generatedResponse.wordsToMention.options.filter(o => o != val);
      this.generatedResponse.wordsToMention.selected = this.generatedResponse.wordsToMention.selected.filter(o => o != val);
      this.updateWordSelection();
    },
    async updateWordSelection() {
      const params = Utils.slice(this.generatedResponse.wordsToMention, ["options", "selected"]);
      await this.updateExtensionSettings("words_to_mention", params);
    },
    updateTonality: debounce(function (value, custom) {
      if (custom) {
        if (this.generatedResponse.customTonality) this.generatedResponse.tonality = "";
        else this.generatedResponse.tonality = "professional";
      } else {
        if (value) this.generatedResponse.customTonality = "";
        else this.generatedResponse.customTonality = "friendly";
      }
      this.updateExtensionSettings("tonality", value);
    }, 300),
    updateLanguage(value, custom) {
      if (custom) {
        if (this.generatedResponse.language.customLanguage) this.generatedResponse.language.use_review_language = false;
        else this.generatedResponse.language.use_review_language = true;
      } else {
        if (value) this.generatedResponse.language.customLanguage = null;
        else this.generatedResponse.language.customLanguage = this.$i18n.locale;
      }
      this.updateExtensionSettings("language", this.$t(`locales.${value}`, "en"));
    },
    mergeSentiment() {
      if (this.review.opinions?.length) {
        let merged = [];
        this.review.opinions.forEach(obj => {
          if (!this.polaritiesToShow.includes(obj.polarity)) return;
          let sentiment = merged.find(m => m.polarity == obj.polarity);
          if (!sentiment) merged.push(obj);
          else sentiment.topics = [...new Set(sentiment.topics.concat(obj.topics))];
        });
        this.review.filteredOpinions = merged;
      }
    },
    formatRating(rating) {
      if (rating) return Utils.formatNumber(Utils.round(rating / 10));
      return null;
    },
    tdClass(value) {
      return Utils.ratings.toCss100(value);
    },
    showTranslateButton() {
      return (this.$i18n.locale != this.review.language) && (this.review.comments.title || this.review.comments?.length);
    },
    useComment(comment, field) {
      if (this.cache[this.params.page][this.reviewIndex].translated && this.translations[this.$i18n.locale]?.[`review_${this.review.id}`]?.[`comment_${field}`])
        return this.translations[this.$i18n.locale][`review_${this.review.id}`][`comment_${field}`];
      return comment;
    },
    useTitle() {
      if (this.cache[this.params.page][this.reviewIndex].translated && this.translations[this.$i18n.locale]?.[this.review.title])
        return this.translations[this.$i18n.locale][this.review.title];
      return this.review.title;
    },
    moreInfoLabel(page, index) {
      if (this.cache[page][index].showMoreInfo) return this.$t("general.collapse");
      return this.$t("general.expand");
    },
    usePolarity(polarity) {
      return this.review.filteredOpinions.find(e => e.polarity == polarity)?.topics;
    },
    async sendReply() {
      this.loading = true;
      try {
        let response = await axios.post(`/v3/companies/${this.review.company.id}/reviews/${this.review.id}/reply`, { message: this.reply  });
        this.$emit("updateReply", response.data.data);
        this.success();
      } catch (err) { this.error(err) }
      this.closeModal();
      this.loading = false;
    },
    async generateResponse() {
      this.loading = true;
      try {
        this.promptParams = await this.buildParamsGenerateResponse();
        const response = await axios.post("/v3/ai/generate_response", this.promptParams);
        this.reply = response.data.data.output.response;
      } catch (err) { this.error(err) }
      this.modals.reply = true;
      this.loading = false;
    },
    async buildParamsGenerateResponse() {
      const response = await axios.get("/v3/ai/response_prompt");
      const langCode = this.generatedResponse.language.use_review_language ? this.review.language : this.generatedResponse.language.customLanguage;

      return {
        prompt: [response.data],
        number_words: this.generatedResponse.limitWords.enabled ? this.generatedResponse.limitWords.value : "No limit",
        tonality: this.generatedResponse.tonality || this.generatedResponse.customTonality,
        friendly_language: this.$t(`locales.${langCode}`, "en"),
        review_id: this.review.id,
        ...(this.generatedResponse.wordsToMention && { words_to_mention: this.generatedResponse.wordsToMention.selected }),
        ...(this.generatedResponse.signName.enabled && { sender_signature: this.generatedResponse.signName.value }),
        ...(this.generatedResponse.mentionPropertyName.enabled && { hotel_name: this.generatedResponse.mentionPropertyName.value })
      };
    },
    success() {
			Swal.fire(this.$t("general.success"), this.$t("daily_operations.reviews.reply_sent_success"), "success");
		},
    error (error) {
      return Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response?.data), "error");
    },
    async markCompleted() {
      this.loading = true;
      await axios.get("/v3/scheduler/connection", { params: { company_id: this.review.company.id, source: "trip_advisor" } });
      this.closeModal();
      this.loading = false;
    },
    async saveNote() {
      this.loading = true;
      let params = {
        body:        this.note,
        email_users: this.emailUsers ? "1" : undefined,
        review_url:  this.reviewLink(this.review),
        review_id:   this.review.review_id
      };

      await axios.post(`/v2/companies/${this.review.company.id}/reviews/${this.review.review_id}/notes`, params);
      if (Array.isArray(this.review.notes)) this.review.notes.push({ body: this.note, date: Date(), user: this.user.name });
      else this.review.notes = [{ body: this.note, date: Date(), user: this.user.name }]
      this.note = "";
      this.modals.note = false;

      this.loading = false;
    },
    by(note) {
      return this.$t("daily_operations.reviews.by_on", { name: note.user, date: dayjs(note.date).format("DD MMM YYYY - hh:mm A") })
    },
    translatedFrom() {
      if (this.cache[this.params.page][this.reviewIndex].language)
        return `${this.$t("surveys_responses.translated_from")} ${this.locales(this.cache[this.params.page][this.reviewIndex].language).name}.`;
      return `${this.$t("surveys_responses.translated_from")} ${this.cache[this.params.page][this.reviewIndex].translatedFrom}.`;
    },
    async openReplyModal() {
      if (this.showAIFunctionality) await this.getExtensionSettings();
      const response = this.review.comments.find(r => r.kind == "manager");
      this.reply = response?.comment || "";
      if (this.showAIFunctionality || this.canReplyLocally) this.modals.reply = true;
      else if (this.canReplyTALocally) this.modals.ta = true;
    },
    groupTopics() {
      const result = [];
      this.review.ratings.forEach(rating => {
        const category = rating.category;
        const find     = result.find(r => r.topic == category);
        rating.title   = this.$t(`topics.${rating.topic}`);
        if (find) {
          find.children.push({ ...rating });
          find.count = find.count + 1;
          find.sum   = find.sum + rating.rating;
        } else {
          result.push({
            topic: category,
            title: this.$t(`ratings.rating_comparison.kpis.${category}`),
            children: [rating],
            count: 1,
            sum: rating.rating
          });
        }
      });
      this.review.topics = result;
    },
    addResponseTemplate() {
      let id = 1;
      if (this.generatedResponse.savedResponses.options.length)
        id = Math.max(...this.generatedResponse.savedResponses.options.map(o => o.id || 0)) + 1;
      const obj = { id, name: this.$t(`daily_operations.reviews.template_number`, { n: id }), value: this.reply };
      this.generatedResponse.savedResponses.value = obj;
      this.updateSavedTemplateArea();
      this.generatedResponse.savedResponses.options = [...this.generatedResponse.savedResponses.options, this.generatedResponse.savedResponses.value];
      this.updateSavedResponses();
    },
    updateTemplate() {
      const id = this.generatedResponse.savedResponses.value?.id;
      if (!id) return;
      this.generatedResponse.savedResponses.value.value = this.reply;
      this.updateSavedTemplateArea();
      const index = this.generatedResponse.savedResponses.options.findIndex(o => o.id == id);
      this.generatedResponse.savedResponses.options[index] = this.generatedResponse.savedResponses.value;
      this.updateSavedResponses();
    },
    async deleteResponseTemplate(ev, id) {
      ev.stopPropagation();
      this.generatedResponse.savedResponses.options = this.generatedResponse.savedResponses.options.filter(o => o.id != id)
      this.generatedResponse.savedResponses.value = {};
      this.updateSavedResponses();
    },
    async updateSavedResponses() {
      await this.updateExtensionSettings("saved_responses", this.generatedResponse.savedResponses.options);
    },
    async updateExtensionSettings(field, value) {
      await this.$store.commit("users/saveExtensionSettings", { data: { [field]: Utils.deepClone(value) }});
      const params = this.savedSettings;
      await axios.post("/v3/users/extension_settings", params);
    },
    updateSavedTemplateArea() {
      if (!this.generatedResponse.savedResponses.value) return;
      let params = {
        words_to_mention: this.generatedResponse.wordsToMention.selected,
        sender_signature: this.generatedResponse.signName.value,
        hotel_name:       this.generatedResponse.mentionPropertyName.value
      };
      params = { ...params, reviewer_name: this.review.reviewer_name };
      let res = this.reply;

      Object.entries(params).forEach(([param, value]) => {
        if (value) res = res.replace(value, `%{${param}}`);
      });

      this.generatedResponse.savedResponses.value.value = res;
    },
    updateResponseArea() {
      let params = {
        words_to_mention: this.generatedResponse.wordsToMention.selected,
        sender_signature: this.generatedResponse.signName.value,
        hotel_name:       this.generatedResponse.mentionPropertyName.value
      };
      params = { ...params, reviewer_name: this.review.reviewer_name };
      let res = this.generatedResponse.savedResponses.value?.value || "";

      Object.entries(params).forEach(([param, value]) => {
        res = res.replace(`%{${param}}`, value);
      });

      this.reply = res;
    },
    closeModal() {
      this.modals.reply  = false;
      this.showOffcanvas = false;
      this.reply = "";
    },
    copyToClipboard() {
      navigator.clipboard.writeText(this.reply);
      if (this.canReplyTALocally) this.modals.ta = true;
    }
  },
  computed: {
    reviewerName() {
      return this.review.reviewer?.name || this.$t("surveys_responses.no_name");
    },
    rating() {
      return this.review.ratings?.find(r => r.category == "overall")?.rating;
    },
    canReplyLocally() {
      return Utils.permittedReviewSources.includes(this.review?.source) && this.permittedReply;
    },
    canReplyTALocally() {
      return /trip_advisor/.test(this.review?.source) && this.isFirefox;
    },
    isFirefox() {
      return Bowser.getParser(window.navigator.userAgent).getBrowserName() == "Firefox";
    },
    reviewRatings() {
      this.groupTopics();
      let res = (this.review.topics || []).map(t => ({ ...t, rating: t.sum / t.count }));
      if (this.showSubratings) res = (this.review.topics || []).flatMap(t => t.children);

      res = res.sort((a, b) => {
        if (a.title < b.title) return -1;
        if (a.title > b.title) return  1;
        return 0;
      });
      res.forEach((d, i) => { // put overall at the first position
        if (d.topic === "overall" || d.category == "overall") {
          res.splice(i, 1);
          res.unshift(d);
        }
      });
      return res;
    },
    languageOptions() {
      return [
        { label: this.$t("daily_operations.reviews.dashboard_languages"), languages: Utils.locales },
        { label: this.$t("general.others"), languages: Object.values(locales) },
      ];
    },
    tonalityOptions() {
      return ["professional", "friendly"].reduce((r, c) => {
        r.push({ text: this.$t(`daily_operations.reviews.${c}`), value: c });
        return r;
      }, []);
    },
    savedSettings() {
      return this.$store.getters["users/extensionSettings"];
    },
    isDirtySavedResponse() {
      const prev = this.savedSettings?.saved_responses?.find(sr => sr.id == this.generatedResponse.savedResponses.value?.id);
      if (!prev && !this.generatedResponse.savedResponses.value?.id) return false;
      return !!["name", "value"].reduce((res, k) => {
        if (this.generatedResponse.savedResponses.value[k] != prev?.[k]) return res + 1;
        return res;
      }, 0);
    },
    showAIFunctionality() {
      return true
    }
  }
}
</script>

<template>
  <li class="event-list review" :class="{ 'cursor-loading': loading }">
    <div
      class="timeline-icon rounded-circle"
      :class="tdClass(rating)"
    >
      <h4 :class="{ 'no-rating': !rating }">
        {{ !rating ? "-" : formatRating(rating) }}
      </h4>
    </div>

    <div class="event-content p-4">
      <div class="response-header d-flex align-items-center justify-content-between mt-0 mb-3 font-18 pb-3">
        <div class="d-flex align-items-center">
          <img v-if="review.source" class="rounded me-0 me-sm-3 ratio-1x1" :src=sourceIcon(review.source) >

          <div class="mt-1 mt-sm-0">
            <h4 class="mb-0">{{ useTitle() }}</h4>
            <span class="mb-0 me-0 me-sm-3">{{ $t("daily_operations.reviews.by_name", { name: reviewerName }) }}</span>
          </div>

          <a v-if="review.reviewer && review.reviewer.email" :href="review.reviewer.email" target="_blank" class="text-primary">
            {{ review.reviewer.email }}
          </a>
        </div>

        <div v-if="review.company && review.company.name" class="float-end">
          {{ $t("daily_operations.reviews.for_company", { name: review.company.name }) }}
        </div>
      </div>

      <div class="review">
        <template v-if="review.comments.length">
          <div class="mb-4" v-for="(comment, commentIndex) in review.comments" :key="comment.kind + commentIndex">
            <div v-if="comment.kind == 'manager'" class="card-body rounded bg-light">
              <h4 class="pb-2 mb-2 border-bottom border-light">{{ $t(`daily_operations.reviews.comment_type.${comment.kind}`) }}</h4>
            <span v-html="useComment(comment.comment, comment.kind)" class="white-space-pre"></span>
            </div>
            <template v-else>
              <h4 class="mb-1">{{ $t(`daily_operations.reviews.comment_type.${comment.kind}`) }}</h4>
              <span v-html="useComment(comment.comment, comment.kind)" class="white-space-pre"></span>
            </template>
          </div>
        </template>
      </div>

      <div class="bottom-navbar" @click.stop>
        <button @click="toggleMoreInfo(params.page, reviewIndex)" class="btn btn-outline-primary me-5 col-12 col-sm-auto mb-2 mb-sm-0" data-bs-toggle="collapse" :data-bs-target="`#review_${review.id}`" aria-expanded="false" :aria-controls="`review_${review.id}`">
          {{ moreInfoLabel(params.page, reviewIndex) }}
        </button>

        <button v-if="showTranslateButton(cache[params.page][reviewIndex])" type="button" class="btn btn-outline-primary me-4 col-12 col-sm-auto mb-2 mb-sm-0"
          @click="cache[params.page][reviewIndex].translated ? revertTranslation(reviewIndex) : getTranslation(review, reviewIndex)" >
          {{ cache[params.page][reviewIndex].translated
              ? $t("surveys_responses.revert")
              : $t("surveys_responses.translate") }}
        </button>

        <a :href="forward(review)" class="btn btn-outline-primary me-4 col-12 col-sm-auto mb-2 mb-sm-0">
          {{ $t("daily_operations.reviews.forward") }}
        </a>

        <button :disabled="false" class="btn btn-outline-primary me-4 mt-2 mt-sm-0 col-12 col-sm-auto" @click="modals.note = true">
          {{ $t("daily_operations.reviews.add_note") }}
        </button>

        <template v-if="review.respondable">
          <button v-if="showAIFunctionality || canReplyLocally || canReplyTALocally" class="btn btn-outline-primary me-4 col-12 col-sm-auto" @click="openReplyModal">
            {{ $t("daily_operations.reviews.reply") }}
          </button>

          <a v-else-if="review.respond_url" :href="review.respond_url" target="_blank" rel="noopener noreferrer" class="btn btn-outline-primary me-4 col-12 col-sm-auto" >
            {{ $t("daily_operations.reviews.reply") }}
          </a>
        </template>

        <div v-else-if="!review.respondable" class="btn me-4 col-12 col-sm-auto p-0" v-b-tooltip.hover :title="$t('daily_operations.reviews.reply_disabled_tooltip')">
          <a href="#" class="btn btn-outline-primary disabled">{{ $t("daily_operations.reviews.reply") }}</a>
        </div>
      </div>

      <div class="translated-from mt-2" v-if="cache[params.page][reviewIndex].translated" style="font-size: 13px;" >
        {{ translatedFrom() }}
      </div>

      <!-- more info box -->
      <div class="collapse row mt-5 pb-3 pt-2" :id="`review_${review.id}`">
        <!-- details -->
        <div class="table-responsive mb-0 col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6">
          <h5 class="m-0">{{ $t("daily_operations.reviews.details") }}</h5>
          <table class="w-100">
            <template v-for="(row, index) in additionalInfo">
              <tr v-if="review[row.field]" :key="index">
                <td class="py-2">
                  <label class="me-2 mb-0">{{ $t(`surveys_responses.additional_info_fields.${row.i18nPath}`) }}</label>
                </td>
                <td class="justify-content-start">
                  {{ review[row.field] }}
                </td>
              </tr>
            </template>
          </table>
        </div>

        <!-- ratings -->
        <div v-if="reviewRatings.length" class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-5 mt-lg-0">
          <div class="d-flex align-items-center justify-content-between">
            <h5 class="m-0">{{ $t("daily_operations.reviews.ratings") }}</h5>
            <b-form-checkbox id="showSubratings" v-model="showSubratings" @click.stop switch class="mb-3 pt-2" size="lg">
              <label for="showSubratings" class="font-size-14">{{ $t("daily_operations.analytics.show_sub_ratings") }}</label>
            </b-form-checkbox>
          </div>

          <div v-for="(topic, i) in reviewRatings" :key="`${topic.topic}_${i}`" class="mt-3">
            <div class="d-flex align-items-center justify-content-between">
              <span class="mb-2 d-flex align-items-center">
                <h5 class="mb-0 me-2">{{ topic.title }}</h5>
                <span v-if="showSubratings && topic.source_title" v-b-tooltip.hover :title="`${$t('daily_operations.reviews.original_source')}: ${topic.source_title}`">
                  <i class="mdi mdi-information-outline"></i>
                </span>
              </span>
              <h5>{{ formatRating(topic.rating) }}</h5>
            </div>
            <div class="progress">
              <div class="progress-bar bg-replab" :class="tdClass(topic.rating)" :style="`width: ${topic.rating}%;`"></div>
            </div>
          </div>
        </div>

        <!-- sentiments -->
        <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-6 mt-1 mt-lg-0">
          <template v-if="review.filteredOpinions && review.filteredOpinions.length > 0">
            <h5 class="question-title mb-3"> {{ $t("surveys_responses.sentiments") }} </h5>
            <div class="d-flex flex-column justify-content-start">
              <template v-for="p in polaritiesToShow" :key="p">
                <div class="me-4 mb-2" :class="p" v-if="usePolarity(p)">
                  <div class="d-flex">
                    <i class="fa font-size-22 me-2" :class="polarityClass[p].icon" v-b-tooltip.hover :title="$t(`surveys_responses.${p}_sentiment`)"></i>
                    <div class="d-flex flex-wrap">
                      <div class="tag text-center" :class="polarityClass[p].tag" v-for="(property, index) in usePolarity(p)" :key="property + index">
                        {{ $t(`topics.${property}`) }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </template>
        </div>

        <!-- previous saved notes -->
        <template v-if="review.notes && review.notes.length">
          <h5 class="mt-5 mb-1">{{ $t("daily_operations.reviews.notes") }}</h5>
          <div v-for="note in review.notes" :key="note.date" class="d-flex flex-column mb-3">
            <span>{{ note.body }}</span>
            <span class="text-muted">{{ by(note) }}</span>
          </div>
        </template>
      </div>

      <BOffcanvas v-model="showOffcanvas" placement="start" :backdrop="false" :noCloseOnBackdrop="true" :noFocus="true" :bodyScrolling="true">
        <HelpSidebar />
      </BOffcanvas>

      <!-- reply modal -->
      <b-modal v-model="modals.reply" class="review-modal" centered header-class="border-0 pb-0" footer-class="border-0 pt-2 p-3" body-class="pt-1" :class="{ 'cursor-loading': loading, 'modal-offcanvas': showOffcanvas }" size="xl" @cancel:modelValue="closeModal" @hide="closeModal" scrollable>
        <template #header>
          <div class="d-flex align-items-center justify-content-between w-100">
            <h5 class="border-0 pb-0 modal-title">{{ $t("daily_operations.reviews.reply_review") }}</h5>
            <div class="d-flex align-items-center">
              <a v-if="showAIFunctionality" href="javascript:void(0);" @click="showOffcanvas = !showOffcanvas" v-b-tooltip.hover :title="$t('general.help')">
                <i class="mdi mdi-help-circle font-size-20"></i>
              </a>
              <button class="btn-close" type="button" @click="closeModal"></button>
            </div>
          </div>
        </template>

        <div class="row pb-2 pt-3">
          <!-- review -->
          <div class="col-5 pe-2 d-flex flex-column">
            <div class="mb-2 d-flex align-items-center">
              <h5 class="m-0">{{ $t("daily_operations.reviews.review") }}</h5>
              <div v-if="showAIFunctionality" class="hide">
                <button class="btn btn-primary ps-2">
                  <i class="mdi mdi-creation me-1"></i>
                  {{ $t("daily_operations.reviews.generate_response") }}
                </button>
                <BButtonGroup>
                  <BButton class="py-1" variant="outline-primary">
                    <i class="mdi mdi-content-save-plus font-size-16"></i>
                  </BButton>
                </BButtonGroup>
              </div>
            </div>

            <div class="position-relative me-2 rounded-3 overflow-auto border border-2 flex-grow-1">
              <div class="position-absolute p-2 w-100">
                <div class="response-header mb-3 d-flex align-items-center justify-content-between">
                  <div class="d-flex align-items-center">
                    <img v-if="review.source" class="rounded me-0 me-sm-3 ratio-1x1" :src=sourceIcon(review.source) style="width:40px;">
                    <div class="mt-1 mt-sm-0">
                      <h5 class="mb-0">{{ useTitle() }}</h5>
                      <div class="d-flex flex-column">
                        <span class="mb-0 me-0 me-sm-3">{{ $t("daily_operations.reviews.by_name", { name: reviewerName }) }}</span>
                        <a v-if="review.reviewer && review.reviewer.email" :href="review.reviewer.email" target="_blank" class="text-primary">
                          {{ review.reviewer.email }}
                        </a>
                      </div>
                    </div>
                  </div>

                  <div class="fw-bold">
                    {{ review.creation_date }}
                  </div>
                </div>

                <!-- comments -->
                <template v-if="review.comments.length">
                  <div v-for="(comment, commentIndex) in review.comments" :key="comment.kind + commentIndex" class="mb-4">
                    <h6 class="mb-1">{{ $t(`daily_operations.reviews.comment_type.${comment.kind}`) }}</h6>
                    <span v-html="useComment(comment.comment, comment.kind)" class="white-space-pre"></span>
                  </div>
                </template>

                <!-- details -->
                <div class="table-responsive mb-0">
                  <h5 class="mb-1">{{ $t("daily_operations.reviews.details") }}</h5>
                  <table class="w-100">
                    <template v-for="(row, index) in additionalInfo">
                      <tr v-if="review[row.field]" :key="index">
                        <td class="pb-1">
                          <label class="me-2 mb-0">{{ $t(`surveys_responses.additional_info_fields.${row.i18nPath}`) }}</label>
                        </td>
                        <td class="justify-content-start">
                          {{ review[row.field] }}
                        </td>
                      </tr>
                    </template>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <!-- reply text -->
          <div class="col-7 ps-0" :class="{ 'position-relative': loading }">
            <div class="mb-2 d-flex align-items-center justify-content-between">
              <h5 class="m-0">{{ $t("daily_operations.reviews.reply_text") }}</h5>
              <div v-if="showAIFunctionality">
                <button class="btn btn-primary ps-2" @click="generateResponse">
                  <i class="mdi mdi-creation me-1"></i>
                  {{ $t("daily_operations.reviews.generate_response") }}
                </button>

                <BButtonGroup class="ms-3" :class="{ 'd-none': !reply.length }">
                  <BButton class="py-1" variant="outline-primary" @click="addResponseTemplate" v-b-tooltip.hover :title="$t('daily_operations.reviews.save_as_template')">
                    <i class="mdi mdi-content-save-plus font-size-16"></i>
                  </BButton>
                  <BButton v-if="(generatedResponse.savedResponses.value || {}).id" class="py-1" variant="outline-primary" @click="updateTemplate" v-b-tooltip.hover :title="$t('daily_operations.reviews.update_template')">
                    <i class="mdi mdi-update font-size-16"></i>
                  </BButton>
                </BButtonGroup>
              </div>
            </div>
            <textarea class="w-100 reply-textarea" v-model="reply" rows="10" :placeholder="$t('daily_operations.reviews.reply_placeholder')" :disabled="loading"></textarea>
            <div v-if="loading" class="w-100 cursor-loading loading position-absolute opacity-75" style="height:calc(100% - 43px);top:43px;"></div>
          </div>
        </div>

        <div v-if="showAIFunctionality" class="row mt-2">
          <!-- response preferences -->
          <div class="col-7">
            <div class="border border-2 rounded-3 d-flex flex-column justify-content-center" :class="{ 'h-100': !(generatedResponse.savedResponses.value || {}).value }">
              <a class="card-title font-weight-bold m-0 chevron d-flex align-items-center justify-content-between w-100 px-3 py-2" style="cursor: pointer;" data-bs-toggle="collapse" :href="`#review_response_preferences_${review.id}`" aria-expanded="false" :aria-controls="`review_response_preferences_${review.id}`">
                <h5 class="m-0">{{ $t("daily_operations.reviews.response_preferences") }}</h5>
                <i class="mdi mdi-chevron-down font-size-20"></i>
              </a>

              <div class="collapse px-2 pt-3 pb-2 overflow-visible-collapse" :id="`review_response_preferences_${review.id}`">
                <!-- Language Preference -->
                <div class="row d-flex align-items-center mb-2">
                  <div class="col-5">
                    <label class="ps-switch-md">{{ $t("daily_operations.reviews.language_preference") }}</label>
                  </div>
                  <div class="col-7 align-items-center" :class="{ 'd-flex': review.language }">
                    <div class="me-3">
                      <BFormCheckbox v-if="review.language" v-model="generatedResponse.language.use_review_language" button button-variant="outline-primary" @input="updateLanguage(review.language, false)">
                        <div class="d-flex align-items-center">
                          <span :class="`flag flag-${locales(review.language, 'language').code}`" class="me-2"></span>
                          {{ $t("daily_operations.reviews.review_language", { l: $t(`locales.${review.language}`) }) }}
                        </div>
                      </BFormCheckbox>
                    </div>

                    <div class="w-100 pt-1">
                      <multiselect
                        :class="{ 'selected-language': generatedResponse.language.customLanguage }"
                        :multiple="false"
                        group-values="languages" group-label="label" :group-select="false"
                        v-model="generatedResponse.language.customLanguage"
                        :options="languageOptions"
                        :showLabels="false"
                        :placeholder="$t('daily_operations.reviews.select_custom_language')"
                        :customLabel="l => $t(`locales.${l}`)"
                        @select="e => updateLanguage(e, true)"
                        @remove="e => updateLanguage(e, true)"
                      >
                        <template v-slot:singleLabel="props">
                          <div v-if="props.option.$groupLabel">
                            {{ props.option.$groupLabel }}
                          </div>
                          <div v-else class="d-flex align-items-center">
                            <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                            <span> {{ locales(props.option).name }} </span>
                          </div>
                        </template>

                        <template v-slot:option="props">
                          <div v-if="props.option.$groupLabel">
                            {{ props.option.$groupLabel }}
                          </div>
                          <div v-else class="d-flex align-items-center">
                            <span :class="`flag flag-${locales(props.option, 'language').code}`" class="me-2"></span>
                            <span> {{ locales(props.option).name }} </span>
                          </div>
                        </template>
                      </multiselect>
                    </div>
                  </div>
                </div>

                <!-- Tonality -->
                <div class="row d-flex align-items-center mb-2">
                  <div class="col-5">
                    <label class="ps-switch-md">{{ $t("daily_operations.reviews.tonality_preference") }}</label>
                  </div>
                  <div class="col-7 d-flex align-items-center">
                    <BFormRadioGroup
                      class="me-3"
                      v-model="generatedResponse.tonality"
                      :options="tonalityOptions"
                      button-variant="outline-primary"
                      buttons
                      @input="e => updateTonality(e, false)"
                    />
                    <input type="text" class="form-control" :class="{ 'outline-3-primary': generatedResponse.customTonality }" :placeholder="$t('daily_operations.reviews.custom_tonality')" v-model="generatedResponse.customTonality" @input="e => updateTonality(e.target.value, true)" />
                  </div>
                </div>

                <!-- Mention the property name in the response -->
                <div class="row d-flex align-items-center mb-2">
                  <div class="col-5">
                    <b-form-checkbox switch size="md" v-model="generatedResponse.mentionPropertyName.enabled">
                      <div class="font-size-14">{{ $t("daily_operations.reviews.mention_property_name") }}</div>
                    </b-form-checkbox>
                  </div>
                  <div class="col-7">
                    <div class="position-relative">
                      <input type="text" class="form-control inner-icon-input" :placeholder="$t('daily_operations.reviews.property_name_placeholder')" v-model="generatedResponse.mentionPropertyName.value" :disabled="!generatedResponse.mentionPropertyName.enabled">
                      <a class="inner-right-icon mdi mdi-content-save font-size-22 cursor-pointer-hover" style="top:1px;right:10px;" v-b-tooltip.hover :title="$t('daily_operations.reviews.save_alias')" @click="updateCompanyAlias"></a>
                    </div>
                  </div>
                </div>

                <!-- Sign with the following name -->
                <div class="row d-flex align-items-center mb-2">
                  <div class="col-5">
                    <b-form-checkbox switch v-model="generatedResponse.signName.enabled" size="md">
                      <span class="me-2 font-size-14">{{ $t("daily_operations.reviews.sign_with_name") }}</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-7">
                    <input type="text" class="form-control" :placeholder="$t('daily_operations.reviews.sign_name_placeholder')" v-model="generatedResponse.signName.value" :disabled="!generatedResponse.signName.enabled" />
                  </div>
                </div>

                <!-- Limit size -->
                <div class="row d-flex align-items-center mb-2">
                  <div class="col-5">
                    <b-form-checkbox switch v-model="generatedResponse.limitWords.enabled" size="md">
                      <span class="me-2 font-size-14">{{ $t("daily_operations.reviews.limit_size") }}</span>
                    </b-form-checkbox>
                  </div>
                  <div class="col-7">
                    <div class="input-group">
                      <input type="number" class="form-control" :disabled="!generatedResponse.limitWords.enabled" v-model="generatedResponse.limitWords.value" aria-describedby="word-limit" />
                      <span class="input-group-text" id="word-limit">{{ $t("daily_operations.reviews.words") }}</span>
                    </div>
                  </div>
                </div>

                <!-- Mention the following words -->
                <div class="row d-flex align-items-center">
                  <div class="col-5">
                    <b-form-checkbox switch v-model="generatedResponse.wordsToMention.enabled" size="md">
                      <div class="font-size-14">{{ $t("daily_operations.reviews.mention_words") }}</div>
                    </b-form-checkbox>
                  </div>
                  <div class="col-7">
                    <multiselect
                      :class="{ disabled: !generatedResponse.wordsToMention.enabled }"
                      :multiple="true"
                      v-model="generatedResponse.wordsToMention.selected"
                      :options="generatedResponse.wordsToMention.options"
                      :showLabels="false"
                      :placeholder="$t('filter.select_option')"
                      :taggable="true"
                      :tag-placeholder="$t('daily_operations.reviews.mention_words_new_tag')"
                      @tag="addWordToMention"
                      @select="updateWordSelection"
                      @remove="updateWordSelection"
                    >
                      <template v-slot:singleLabel="props">
                        {{ props.label }}
                      </template>

                      <template v-slot:option="props">
                        <div class="d-flex align-items-center justify-content-between">
                          <span>
                            {{ props.option.label ? props.option.label : props.option }}
                          </span>
                          <a v-if="!(props.option && props.option.label)" href="javascript:void(0);" @click="e => removeWordsToMention(e, props.option)" v-b-tooltip.hover :title="$t('general.delete')">
                            <i class="mdi mdi-trash-can font-size-18"></i>
                          </a>
                        </div>
                      </template>
                    </multiselect>
                  </div>
                </div>
              </div>
						</div>
          </div>

          <!-- saved responses -->
          <div class="col-5 ps-0">
            <div class="border border-2 rounded-3 p-2 ps-3 w-100">
              <div class="d-flex align-items-center">
                <h5 class="mb-0 me-3">{{ $t("daily_operations.reviews.saved_responses") }}</h5>
                <div class="d-flex align-items-center justify-content-center flex-grow-1 pt-1">
                  <multiselect
                    class="editable-multiselect"
                    :class="{ 'pe-0': !generatedResponse.savedResponses.value }"
                    v-model="generatedResponse.savedResponses.value"
                    :options="generatedResponse.savedResponses.options"
                    :showLabels="false"
                    :placeholder="$t('filter.select_option')"
                    track-by="name"
                    label="name"
                  >
                    <template v-slot:option="props">
                      <div class="d-flex align-items-center justify-content-between">
                        <span>
                          {{ props.option.label ? props.option.label : props.option.name }}
                        </span>
                        <a v-if="!(props.option && props.option.label)" href="javascript:void(0);" @click="e => deleteResponseTemplate(e, props.option.id)" v-b-tooltip.hover :title="$t('general.delete')">
                          <i class="mdi mdi-trash-can font-size-18"></i>
                        </a>
                      </div>
                    </template>

                    <template v-slot:selection>
                      <input v-if="generatedResponse.savedResponses.value" type="text" class="form-control inner-icon-input editable-input" :placeholder="$t('filter.select_option')" v-model="generatedResponse.savedResponses.value.name">
                    </template>

                  </multiselect>

                  <div class="d-flex align-items-center justify-content-between" v-if="(generatedResponse.savedResponses.value || {}).value">
                    <a href="javascript:void(0);" class="me-1 font-size-22" @click="updateSavedResponses" v-b-tooltip.hover :title="$t('general.save')">
                      <i v-if="isDirtySavedResponse" class="mdi mdi-content-save-alert"></i>
                      <i v-else class="mdi mdi-content-save-check"></i>
                    </a>
                    <a href="javascript:void(0);" class="me-1" @click="updateResponseArea" v-b-tooltip.hover :title="$t('daily_operations.reviews.copy_to_reply')">
                      <i class="mdi mdi-file-document-arrow-right font-size-20"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div v-if="(generatedResponse.savedResponses.value || {}).value" :class="{ 'position-relative': loading }">
                <textarea class="w-100 reply-textarea mt-1" rows="10" v-model="(generatedResponse.savedResponses.value || {}).value" :placeholder="$t('daily_operations.reviews.saved_responses_placeholder')" :disabled="loading"></textarea>
                <div v-if="loading" class="w-100 h-100 cursor-loading loading position-absolute opacity-75" style="top:0;"></div>
              </div>
            </div>
          </div>
        </div>

        <template #footer>
          <div class="float-end m-0">
            <button v-if="canReplyLocally" class="btn btn-primary" :disabled="!reply.length" @click="sendReply">
              {{ $t("daily_operations.reviews.send_reply") }}
            </button>

            <template v-else>
              <a v-if="canReplyTALocally" @click="copyToClipboard" class="btn btn-primary" :class="{ disabled: !reply.length }">
                {{ $t("daily_operations.reviews.copy_and_go", { source: review.source_name }) }}
              </a>

              <a v-else :href="review.respond_url" target="_blank" rel="noopener noreferrer" @click="copyToClipboard" class="btn btn-primary" :class="{ disabled: !reply.length }">
                {{ $t("daily_operations.reviews.copy_and_go", { source: review.source_name }) }}
              </a>
            </template>
          </div>
        </template>
      </b-modal>

      <!-- trip_advisor modal -->
      <b-modal v-if="canReplyTALocally" v-model="modals.ta" centered hide-footer header-class="border-0 pb-0" body-class="pt-1" :title="$t('daily_operations.reviews.reply_ta')" size="xl">
        <div class="ratio ratio-4x3">
          <iframe
            v-if="modals.reply"
            :src="review.respond_url"
            width="100%"
            height="auto"
            scrolling="auto"
            frameborder="0"
            style="border: none; width: 100%;"
            allowfullscreen
            allow-same-origin
          >
          </iframe>
        </div>

        <div class="footer-nav d-flex justify-content-between mt-3 mb-1">
          <button class="btn btn-outline-secondary" @click="modals.ta = false">
            {{ $t("surveys_list.cancel") }}
          </button>

          <button class="btn btn-primary" @click="markCompleted">
            {{ $t("daily_operations.reviews.completed") }}
          </button>
        </div>
      </b-modal>

      <!-- note modal -->
      <b-modal v-model="modals.note" centered hide-footer header-class="border-0 pb-0" body-class="pt-1" :title="$t('daily_operations.reviews.add_note')" size="xl" @update:modelValue="note = ''">
        <div class="pb-2 pt-3 d-flex flex-column">
          <span>{{ $t("daily_operations.reviews.note_explanation") }}</span>
          <textarea class="w-100 reply-textarea mt-1" v-model="note" rows="7" :placeholder="$t('daily_operations.reviews.note_placeholder')"></textarea>

          <div class="d-sm-flex align-items-center justify-content-between mt-3 ps-1">
            <b-form-checkbox v-model="emailUsers" :id="`review_${review.id}_email_users`">
              <label class="form-label m-0" :for="`review_${review.id}_email_users`">{{ $t("daily_operations.reviews.email_users") }}</label>
            </b-form-checkbox>
          </div>

          <div class="footer-nav d-flex justify-content-between mt-3 mb-1">
            <button class="btn btn-outline-secondary" @click="modals.note = false">
              {{ $t("surveys_list.cancel") }}
            </button>

            <button class="btn btn-primary float-end" :disabled="note.length <= 0" @click="saveNote">{{ $t("daily_operations.reviews.save_note") }}</button>
          </div>
        </div>
      </b-modal>
    </div>

    <div class="event-date">
      <h5 class="mt-0 mb-0">{{ getMonth(review.published_date) }}&nbsp;</h5>
      <h5 class="mt-0 mb-0">{{ toDate(review.published_date).getDate() }}&nbsp;</h5>
      <p class="mb-0">
        <strong>{{ toDate(review.published_date).getFullYear() }}&nbsp;</strong>
      </p>
    </div>
  </li>
</template>
