<script>
import PageHeader from "@/components/page-header";
import Filters    from "@/components/filter";
import DataUtils  from "@/js/data-utils";
import ReputationUtils from "@/js/reputation-utils"
import Modal from "@/components/modal-users-reports";
import dayjs from "dayjs";
import Utils from "@/js/utils";

export default {
  data() {
    return {
      loading: false,
      filterParams: "",
      user: {},
      myData: { assignments: { groups: [], companies: [] } },
      users: [],
      companies: [],
      scheduleUser: { assignments: { groups: [], companies: [] } },
      reportSchedules: [],
      searchString: "",
      filterCompanies: [],
      filterGroups: [],
      filterType: [],
      typeHash: {
        "User": "user",
        "Report Recipient": "report_recipient"
      },
      modals: {
        add_report_recipient: false,
        add_user: false,
        edit_user: false
      },
      companiesHash: {},
      groupsHash: {},
      editedUser: {}
    };
  },
  props: ["filterCols", "tableCols", "ignoreProperties"],
  components: { PageHeader, Filters, Modal },
  methods: {
    ...DataUtils,
    ...ReputationUtils,
    async load() {
      if (!this.user.id) {
        this.user = await this.$store.dispatch("user/fetch");
        this.user = Utils.deepClone(this.user);
      }
      if (this.user.manager) await this.useManager();
      this.loadUsers();
    },
    async loadUsers() {
      this.loading = true;
      let users = await this.$store.dispatch("users/fetch", { contract_id: this.contract.id });
      this.users = Utils.deepClone(users);
      this.formatUsers();
      this.modals.edit_user = false;
      if (!this.user.manager) {
        let me = this.users.find(u => u.id == this.user?.id);
        this.myData = Utils.deepClone(me);
      }
      let companies         = this.useCompanies(this.myData);
      this.user.company_ids = companies.map(c => c.id);
      this.companies        = [...this.companies, ...companies];
      if (!this.user.admin) {
        this.users = this.users.filter(u => {
          let ids = this.useCompanies(u).map(c => c.id);
          return ids.every(id => this.user.company_ids.includes(id))
        })
      }
      this.loading = false;
    },
    formatAssignment(user) {
      const dataMap = new Map();
      const data = (user.assignments.groups || []).map(group => {
        (group.companies || []).forEach(c => dataMap.set(c.id, c));
        dataMap.set(group.id, group);
        return group;
      });
      (user.assignments.companies || []).forEach(company => {
        if (!dataMap.has(company.id)) {
          data.push(company);
          dataMap.set(company.id, company);
        }
      });
      const number = data.length;
      const others = number > 1 ? this.$tc("user_management.users.others", number - 1, { number: number - 1 }) : "";
      if (number) return data[0].name + " " + others;
      return this.$t("user_management.users.none");
    },
    formatDate(date) {
      if (date != null) return dayjs(date).format("DD/MM/YYYY - HH:mm");
      return this.$t("user_management.users.never");
    },
    formatType(type) {
      if (type == "user") return this.$tc("user_management.users.user", 1);
      if (type == "report_recipient") return this.$t("user_management.users.report_recipient");
    },
    isSubset(companies) {
      let companyIds = companies?.map(c => c.id);
      let diff = companyIds.filter(c => !this.user.company_ids?.includes(c));
      return diff.length == 0;
    },
    useCompanyName(id) {
      return this.companies.find(c => c.id == id)?.name;
    },
    updateUsers({ user, action }) {
      const index = this.users.findIndex(u => u.id == user.id);
      if (action == "remove_permissions" && index != -1) this.users.splice(index, 1);
      else if (index != -1) this.users[index] = user;
      else this.users.unshift(user);

      this.formatUsers();
      this.modals.edit_user = false;
      this.$store.commit("users/saveUsers", { users: this.users, params: { contract_id: this.contract.id } })
    },
    formatUsers() {
      this.users.forEach(user => {
        user["assignments_str"] = this.formatAssignment(user);
        user["last_seen"]       = this.formatDate(user.last_seen_at);
        user["type"]            = this.formatType(this.typeHash[user.user_management_type]);
        if (user["full_name"].length == 0) user["full_name"] = "-";
      });
    },
    editUserTitle(item) {
      if (item.type == "User") return this.$t("user_management.users.edit_user");
      return this.$t("user_management.users.edit_report_recipient");
    },
    async useManager() {
      const contract_id = this.contract.id;
      if (!this.companiesHash[contract_id])
        this.companiesHash[contract_id] = await this.$store.dispatch("companies/fetch", { contract_id });
      if (!this.groupsHash[contract_id])
        this.groupsHash[contract_id] = await this.$store.dispatch("groups/fetch", { contract_id });

      if (!this.ignoreProperties) this.companiesHash[contract_id] = this.removeDuplicates();

      this.myData = {
        ...this.user,
        assignments: {
          groups: this.groupsHash[contract_id],
          companies: this.companiesHash[contract_id] || []
        }
      };
    },
    removeDuplicates() {
      let companies = this.companiesHash[this.contract.id];
      let groups    = this.groupsHash[this.contract.id];

      groups.forEach(group => {
        companies = companies.filter(c => !group.company_ids.includes(c.id));
      });

      return companies;
    },
    openEditUserModal(user) {
      this.modals.edit_user = true;
      this.editedUser = user;
    }
  },
  computed: {
    fields() {
      return this.tableCols.map(col => ({ value: col, text: this.$t(`user_management.users.table_cols.${col}`), sortable: col != "actions" }));
		},
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    computedCompanies() {
      return this.companies.map(c => c.id).filter(Utils.uniq);
    },
    filteredUsers() {
      let res = this.users;
      const searchText = Utils.normalizeString(this.searchString);
      if (searchText.length > 0) res = res.filter(user => {
        const name  = Utils.normalizeString(user.full_name);
        const email = Utils.normalizeString(user.email);
        return name.includes(searchText) || email.includes(searchText);
      });

      if (this.filterGroups.length > 0) res = res.filter(user => {
        return this.filterGroups.filter(c => !user.assignments.groups.map(a => a.id).includes(c)).length == 0;
      });

      if (this.filterCompanies.length > 0) res = res.filter(user => {
        return this.filterCompanies.filter(c => !user.assignments.companies.map(a => a.id).includes(c)).length == 0;
      });

      if (this.filterType.length > 0) res = res.filter(user => {
        return this.filterType.includes(this.typeHash[user.user_management_type]);
      });
      return res;
    },
    rowsPerPage() {
      this.$refs["usersTable"]?.updateRowsPerPageActiveOption(this.filteredUsers.length);
      return this.filteredUsers.length;
    }
  },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.load();
      },
      immediate: true
    },
    filterParams: function () {
      this.filterCompanies = this.filterParams.data.company_ids || [];
      this.filterGroups    = this.filterParams.data.group_ids?.flat()?.map(g => Number(g)) || [];
      this.filterType      = this.filterParams.data.user_type || "";
      this.searchString    = this.filterParams.data.search || "";
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$tc('user_management.users.user', 2)">
      <template v-slot:subtitle>
        <div>
          <button class="btn btn-primary" v-b-tooltip.hover :title="$t('user_management.users.type_tooltip')" @click="modals.add_report_recipient = true"> +&nbsp;{{ $t("user_management.users.add_report_recipient") }} </button>
          <button class="btn btn-primary ms-3" @click="modals.add_user = true"> +&nbsp;{{ $t("user_management.users.add_user") }}</button>
        </div>
      </template>
    </PageHeader>

    <Filters @filter:created="saveFilterParams" @filter:update="saveFilterParams" @filter:change="saveFilterParams" :cols="filterCols" :notifyChange="true" />

    <div class="card" :class="{ 'loading': loading }" style="min-height: 250px;">
      <div class="card-body">
        <!-- Add report recipient -->
        <b-modal v-model="modals.add_report_recipient" centered hide-footer hide-header size="lg" >
          <Modal v-if="modals.add_report_recipient" context="user_management" :contract="contract" :modals="modals" @updateUsers="updateUsers" :title="$t('user_management.users.add_report_recipient')" type="add_report_recipient" modalId="add_report_recipient" :myData="myData" :users="users" :hideGroupCompanies="ignoreProperties" />
        </b-modal>

        <!-- Invite user -->
        <b-modal v-model="modals.add_user" centered hide-footer hide-header size="lg" >
          <Modal v-if="modals.add_user" context="user_management" :contract="contract" :modals="modals" @updateUsers="updateUsers" :title="$t('user_management.users.add_user')" type="add_user" modalId="add_user" :myData="myData" :users="users" :hideGroupCompanies="ignoreProperties" />
        </b-modal>

        <div v-if="filteredUsers.length > 0">
          <data-table
            ref="usersTable"
            :items="filteredUsers"
            :headers="fields"
            :rows-per-page="rowsPerPage"
            class="thead-light"
            header-text-direction="center"
            body-text-direction="center"
            sort-by="full_name"
            sort-type="asc"
            hide-footer
          >
            <template #header-type="item">
              <div class="d-flex align-items-center">
                {{ item.text }}
              <span v-b-tooltip.hover :title="$t('user_management.users.type_tooltip')" class="ms-2">
                <i class="mdi mdi-help-circle"></i>
              </span>
              </div>
            </template>
            <template #item-actions="item">
              <a href="#" class="text-primary" @click="openEditUserModal(item)">{{ $t("user_management.users.edit") }}</a>
            </template>
          </data-table>

          <!-- Edit user -->
          <b-modal v-model="modals.edit_user" centered hide-footer hide-header size="lg" >
            <Modal v-if="modals.edit_user" context="user_management" :contract="contract" :modals="modals" @updateUsers="updateUsers" :title="editUserTitle(editedUser)" type="edit_user" modalId="edit_user" :userData="editedUser" :myData="myData" :hideGroupCompanies="ignoreProperties" />
          </b-modal>
        </div>
      </div>
    </div>
  </div>
</template>
