<script>
import dayjs from "dayjs";
import quarterOfYear from "dayjs/plugin/quarterOfYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(quarterOfYear);
dayjs.extend(weekOfYear);

export default {
  name: "date-picker",
  data() {
    return {
      periodHash: {
        year:    { levels: ["year"],                  options: Array.from(Array(5).keys())  },
        quarter: { levels: ["year", "quarter"],       options: Array.from(Array(4).keys())  },
        month:   { levels: ["year", "month"],         options: Array.from(Array(12).keys()).reverse() },
        bimonth: { levels: ["year", "bimonth"],       options: Array.from(Array(6).keys().map(n => n * 2)).reverse() },
        week:    { levels: ["year", "month", "week"], options: Array.from(Array(4).keys())  },
        day:     { levels: ["year", "month", "day"],  options: [] }
      },
      model: []
    };
  },
  props: ["reportTemplateId", "period", "levelIndex", "previousValue"],
  created() {
    this.createValues();
  },
  watch: {
    reportTemplateId() { this.model = []; }
  },
  methods: {
    createValues() {
      let options = this.periodHash[this.currentPeriod].options;
      if (this.currentPeriod == "year") options = options.map(o => this.periodName(o))
      if (["month", "bimonth"].includes(this.currentPeriod)) {
        let currentMonth = dayjs().month();
        if (this.isCurrent()) options = options.filter(o => o <= currentMonth);
        options = options.map(o => this.periodName(o));
      }
      if (this.currentPeriod == "week") {
        let currentWeek = dayjs().week() - dayjs(dayjs().format("YYYY-MM")).week();
        if (this.isCurrent()) options.splice(currentWeek + 1);
        options = options.map(o => this.periodName(o));
      }
      if (this.currentPeriod == "quarter") {
        let currentQuarter = dayjs().quarter();
        if (this.isCurrent()) options.splice(currentQuarter);
        options = options.map(o => this.periodName(o));
      }
      if (this.currentPeriod == "day") {
        let currentDay = dayjs().date();
        const startDate = dayjs(this.parsedPreviousValue[0]);
        const endDate = dayjs(this.parsedPreviousValue[1]);
        let numberOfDays = endDate.diff(startDate, "day") + 1;
        if (this.isCurrent()) numberOfDays = currentDay;
        options = Array.from({ length: numberOfDays }, (_, i) => startDate.add(i, "day").date());
      }
      this.periodHash[this.currentPeriod].options = options;
    },
    periodName(value) {
      let date = dayjs().startOf('year');
      if (this.currentPeriod == "year")    return dayjs().subtract(value, "year").year();
      if (this.currentPeriod == "quarter") return `${this.$t("general.quarter")} ${dayjs(date).add(value, "quarter").quarter()}`;
      if (["month", "bimonth"].includes(this.currentPeriod)) return dayjs(date).add(value, "month").format("MMMM");
      if (this.currentPeriod == "week")    return `${this.$t("general.week")} ${value + 1}`;
    },
    isCurrent() {
      const isSameMonth = dayjs(this.parsedPreviousValue[0]).month() == dayjs().month();
      const isSameYear  = dayjs(this.parsedPreviousValue[0]).year()  == dayjs().year();
      if (this.currentPeriod == "week") return isSameMonth;
      if (this.currentPeriod == "day")  return isSameMonth && isSameYear;
      if (/quarter|month|bimonth/.test(this.currentPeriod)) return isSameYear;
      return false;
    },
    showNextPeriod(value) {
      let fullValue = this.composedValue(value);
      return (this.levelIndex || 0) < this.periodHash[this.period].levels.length - 1 && this.model.includes(fullValue)
    },
    composedValue(value) {
      let result;
      if (this.currentPeriod == "year")    result = [`${value}-01-01`, `${value}-12-31`];
      if (this.currentPeriod == "quarter") {
        let year = dayjs(this.parsedPreviousValue[0]).year();
        let quarter = parseInt(value.at(-1));
        result = [dayjs(`${year}-${3*(quarter - 1) + 1}-01`).startOf('month').format("YYYY-MM-DD"), dayjs(`${year}-${3 * quarter}-01`).endOf('month').format("YYYY-MM-DD")];
      }
      if (this.currentPeriod == "month") {
        let year = dayjs(this.parsedPreviousValue[0]).year();
        result = [dayjs(`${year}-${value}`).format("YYYY-MM") + "-01", dayjs(`${year}-${value}`).format("YYYY-MM")+`-${dayjs(`${year}-${value}`).endOf("month").date()}`];
      }
      if (this.currentPeriod == "bimonth") {
        let year = dayjs(this.parsedPreviousValue[0]).year();
        result = [dayjs(`${year}-${value}`).format("YYYY-MM") + "-01", dayjs(`${year}-${value}`).format("YYYY-MM")+`-${dayjs(`${year}-${value}`).endOf("month").date()}`];
      }
      if (this.currentPeriod == "week") {
        let year  = dayjs(this.parsedPreviousValue[0]).year();
        let month = dayjs(this.parsedPreviousValue[0]).month() + 1;
        result = [this.firstDayOfWeek(month, year, parseInt(value.at(-1))).format("YYYY-MM-DD"), this.firstDayOfWeek(month, year, parseInt(value.at(-1))).add(6, "day").format("YYYY-MM-DD")];
      }
      if (this.currentPeriod == "day") {
        let year  = dayjs(this.parsedPreviousValue[0]).year();
        let month = dayjs(this.parsedPreviousValue[0]).month() + 1;
        result = [`${year}-${month}-${value}`, `${year}-${month}-${value}`];
      }
      return JSON.stringify(result);
    },
    firstDayOfWeek(month, year, n) {
      let firstDayOfMonth = dayjs(`${year}-${month}-01`);
      let firstDayOfWeek = firstDayOfMonth.day();
      let daysToFirstWeekStart = 7*(n - 1) + 1;
      if (firstDayOfWeek != 0) daysToFirstWeekStart = (7*n - firstDayOfWeek + 1);
      let nthWeekFirstDay = firstDayOfMonth.add(daysToFirstWeekStart, "day");
      return nthWeekFirstDay;
    },
    name(value) {
      if (this.previousValue) return `${this.currentPeriod}${this.previousValue}`;
      return value;
    },
    emitUpdate(ev) {
      let event = { value: ev.value || ev, checked: ev.checked };
      let eventName = "updateModel";
      if (!this.levelIndex && Array.isArray(event.value) || this.period == "year") eventName = "updateDatePicker";
      if (this.levelIndex == this.periodHash[this.period].levels.length - 1 || this.period == "year") {
        event.value   = JSON.parse(ev.target.value);
        event.checked = ev.target.checked;
      }
      this.$emit(eventName, event);
    }
  },
  computed: {
    parsedPreviousValue() {
      return JSON.parse(this.previousValue || "[]");
    },
    currentPeriod() {
      return this.periodHash[this.period].levels[this.levelIndex || 0];
    }
  }
}
</script>

<template>
  <div>
    <h4 class="form-label font-size-14 position-absolute test" :id="currentPeriod" :class="{ 'ps-5': levelIndex, 'ms-3': !levelIndex }" style="top: -5px;">{{ $t(`general.${currentPeriod}`) }}</h4>

    <div v-for="value in periodHash[currentPeriod].options" :key="name(value)" class="d-flex" :class="{'mt-2': currentPeriod == 'year' }">
      <div class="ms-3 mb-1 custom-control custom-control-inline custom-checkbox b-custom-control-m" :class="{ 'ms-5': levelIndex > 0 }" style="width: 200px;">
        <input type="checkbox" :name="name(value)" :id="composedValue(value)" class="custom-control-input" v-model="model" :value="composedValue(value)" @input="emitUpdate" />
        <label class="custom-control-label form-label active font-weight-normal font-size-14" :for="composedValue(value)">{{ value }}</label>
      </div>

      <template v-if="showNextPeriod(value)">
        <date-picker @updateModel="emitUpdate" :period="period" :levelIndex="(levelIndex || 0) + 1" :previousValue="composedValue(value)" />
      </template>
    </div>
  </div>
</template>
