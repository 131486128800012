import axios from "axios";

export const state = { data: {}, dataPromises: {} };

export const getters = {};

export const mutations = {
  saveData(state, { data, params }) {
    state.data[params] = data;
  },
  saveDataPromise(state, { promise, params }) {
    state.dataPromises[params] = promise;
  },
};

const segmentPathsMap = {
  numerical_rating: 'ratings',
  sentiment_rating: 'sentiment',
  tes_rating:       'tes',
  reviews_count:    'ratings',
  sentiment_reviews_count: 'sentiment',
  mentions_count:   'sentiment',
  sentiment_count:  'sentiment'
}
const paramsKeys = ["period", "start_date", "end_date", "subscription_ids",
"company_ids", "group_ids", "trends", "segment", "ctx", "group_by", "per",
"sources", "compositions", "languages", "countries", "property_types"]

export const actions = {
  async fetch({ commit }, params) {
    let p  = paramsKeys.reduce((h, k) => { if (params[k]) h[k] = params[k]; return h }, {})
    let rt = segmentPathsMap[params.ratingType]
    let stringParams = JSON.stringify({ ...p, rt });

    if (state.data[stringParams])         return state.data[stringParams];
    if (state.dataPromises[stringParams]) return state.dataPromises[stringParams];

    let promise = axios.get(`/v3/${rt}/${p['ctx']}`, { params: { ...p }}).then(res => {
      let data = res.data.data
      if (rt == 'tes' && !p.per && !p.segment) data = data[0].tes
      commit("saveData", { data , params: stringParams });
      return data;
    });

    commit("saveDataPromise", { promise, params: stringParams });

    return promise;
  },
  async kpi({ commit }, params) {
    let p  = paramsKeys.reduce((h, k) => { if (params[k]) h[k] = params[k]; return h }, {})
    let rt = segmentPathsMap[params.ratingType]
    let stringParams = JSON.stringify({ ...p, ctx: "kpi", rt });

    if (state.data[stringParams])         return state.data[stringParams];
    if (state.dataPromises[stringParams]) return state.dataPromises[stringParams];

    let promise = axios.get(`/v3/${rt}/kpi`, { params: { ...p }}).then(res => {
      commit("saveData", { data: res.data.data, params: stringParams });
      return res.data.data;
    });

    commit("saveDataPromise", { promise, params: stringParams });

    return promise;
  },
  async executive({ commit }, params) {
    let p = {
      ...window.oly.utils.slice(params, ["period", "start_date", "subscription_ids", "page_context"]),
      segments:    params.segmentOption,
      sub_context: params.sub_context == "data" ? "main" : params.sub_context
    };
    let stringParams = JSON.stringify({ ...p, ctx: "executive" });

    if (state.data[stringParams])         return state.data[stringParams];
    if (state.dataPromises[stringParams]) return state.dataPromises[stringParams];

    let promise = axios.get("/v3/destination/executive-kpis", { params: { ...p }}).then(res => {
      commit("saveData", { data: res.data.data, params: stringParams });
      return res.data.data;
    });

    return promise;
  },
  async response_rate({ commit }, params) {
    let p = window.oly.utils.slice(params, ["start_date", "end_date", "subscription_ids", "company_ids", "group_ids", "trends", "segment", "sources", "per"]);
    let stringParams = JSON.stringify({ ...p, ctx: "response_rate" });

    if (state.data[stringParams])         return state.data[stringParams];
    if (state.dataPromises[stringParams]) return state.dataPromises[stringParams];

    let promise = axios.get("/v3/response_rate", { params: { ...p }}).then(res => {
      commit("saveData", { data: res.data.data, params: stringParams });
      return res.data.data;
    });

    commit("saveDataPromise", { promise, params: stringParams });

    return promise;
  }
}
