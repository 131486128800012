<script>
export default {
  data() {
    return {
      height: 15
    }
  },
  props: ["settings", "edition"],
  watch: {
    "settings.height": {
      handler: function () {
        this.$nextTick(() => {
          this.height = this.settings.height || 15;
        })
      },
      immediate: true
    }
  }
}
</script>

<template>
  <div :style="`height:${height}px;`" class="reports-spacer" :class="{ edition }">
    {{ $t("reports.components_name.spacer") }}
  </div>
</template>