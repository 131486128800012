<script>
import Reports from "@/js/reports";
import dayjs from "dayjs";

export default{
  data () {
    return {
      data: null,
      date: null,
      dateFormats: {
        day:     "DD-MM-YYYY",
        week:    "DD MMM YY",
        month:   "MMMM",
        quarter: "[Q]Q YYYY"
      }
    }
  },
  props:      ['start_date', 'end_date', 'settings', 'company_id'],
  components: {},
  mounted() {
    this.load()
  },
  methods: {
    ...Reports.requests,
    async load () {
      this.loading   = true;
      const response = await this.getReports("most-reviews-day");
      this.data      = response?.slice(1);
      this.date      = this.data[0][0];
      this.loading   = false;
    }
  },
  computed: {
    formattedDate() {
      if (!this.date) return "";
      return dayjs(this.date).format(this.dateFormats[this.settings.group_per || "day"]);
    }
  }
}
</script>

<template>
  <div style='background-color: var(--bs-primary); color: white; padding: 7px; text-align: center;' v-if='data && date'>
    <span :style="`font-size: ${settings.fontsize || 14}px`" > {{ $t('reports.bar_most_reviews_day.text', { d: formattedDate }) }} </span>
  </div>
</template>
