<script>
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";
import Utils     from "@/js/utils";
import dayjs     from "dayjs";

export default {
  data() {
    return {
      loading: true,
      error: false,
      chartData: [],
      total: null,
      periodHash: {
        day:     "day",
        week:    "day_of_week",
        month:   "month",
        quarter: "quarter",
        year:    "year"
      },
      dateFormat: {
        day:     date => dayjs(date).format("DD"),
        week:    date => dayjs().day(date - 1).format("dddd"),
        month:   date => dayjs(date).format("MMM"),
        quarter: date => dayjs(date).format("[Q]Q YYYY"),
        year:    date => dayjs(date).format("YYYY"),
      },
      groupByValuesMap: {
        numerical_rating:      "ratings",
        sentiment_rating:      "opinions",
        tes_rating:            "tes",
        reviews_count:         "ratings",
        mentions_count:        "opinions",
        // sentiment_count:       "opinions"
      },
      valuesMap: {
        numerical_rating:      "value",
        sentiment_rating:      "sentiment_score",
        tes_rating:            "score",
        reviews_count:         "review_count",
        mentions_count:        "opinions_count",
      },
    };
  },
  props: ["settings", "template", "start_date", "end_date", "group_ids", "group_id", "company_id"],
  components: { NotLoaded, Loading },
  mounted() {
    this.load();
  },
  methods: {
    async load() {
      this.loading = true;
      const promises = [];
      const segment = this.settings.segment;
      const params = {
        [this.paramsKey]: this[this.kpiKey],
        ratingType:       segment,
        start_date:       this.start_date,
        end_date:         this.end_date,
        segment:          segment,
        ctx:              "stats",
        group_by:         "period",
        per:              this.periodHash[this.settings.period] || this.settings.period
      }
      promises.push(this.$store.dispatch("kpis/fetch", params)
        .then(response => {
          this.chartData = response.map(d => ({ date: d.date, data: d[this.groupByValuesMap[segment]].find(r => r.topic == (this.settings.segmentOption || "overall")) }));
          if (!/_count/.test(segment)) this.total = 10;
          else this.total = this.chartData.reduce((res, curr) => res + (this.getValue(curr) || 0), 0);
        })
        .catch((e) => { this.error = true; throw e })
      );
      await Promise.all(promises);
      this.loading = false;
    },
    getValue(kpi) {
      const segment = this.settings.segment;
      let val = kpi?.data?.[this.valuesMap[segment]];
      if (segment == "numerical_rating") val = val / 10;
      return val;
    }
  },
  computed: {
    kpiKey() {
      if (this.template.based_on == "companies") return "company_id";
      return "group_id";
    },
    paramsKey() { // params for the endpoints
      if (this.template.based_on == "companies") return "company_ids";
      return "group_ids";
    },
    chartOptions() {
      return {
        chart: { type: "bar", toolbar: { show: false } },
        dataLabels: {
          enabled: true,
          formatter: val => {
            let formattedVal = val;
            if (!/_count/.test(this.settings.segment)) formattedVal = Utils.round(val);
            if (this.settings.use_percentage) return `${formattedVal}%`;
            return formattedVal;
          }
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            dataLabels: { position: "top" }
          }
        },
        labels: [],
        tooltip: { enabled: false },
        colors: [this.template.dashboard_template.styles.primary_color || "#4090cb"],
        // ["#4090cb", "#a3cae0", "#e74c5e", "#47bd9a", "#964b00", "#f9d570", "#2a8251", "#f408a9", "#26e6ee", "#ffa500", "#0841ab", "#02610d"],
        legend: { show: false },
        xaxis: {
          type: "category",
          categories: this.chartData.map(d => this.dateFormat[this.settings.period](d.date)),
        },
        yaxis: {
          labels: {
            formatter: val => {
              if (this.settings.use_percentage) return `${val}%`;
              return val;
            }
          },
        }
      }
    },
    series() {
      return [{ data: this.chartData.map(d => {
        let value = this.getValue(d) || null;
        if (this.settings.use_percentage) return Utils.round(100 * (value / this.total));
        return value;
      })}];
    },
    showChart() {
      return this.series.length && !this.loading;
    }
  },
}
</script>

<template>
  <div :style="`height:${this.settings.height || 300}px;`">
    <apexchart v-if="showChart" class="apex-charts" height="100%" dir="ltr" :series="series" :options="chartOptions"></apexchart>
    <NotLoaded :data="chartData" :error="error" v-else-if="!loading" />
    <Loading v-else-if="loading" />
  </div>
</template>
