<script>
import Utils from "@/js/utils";
import axios from "axios";
import Swal from "sweetalert2";
import Multiselect from "vue-multiselect";
import DatePicker  from "@/components/date-picker";
import dayjs from "dayjs";

export default {
  data() {
    return {
      loading: false,
      user: null,
      reportTemplate: null,
      email: null,
      first_name: "",
      surname: "",
      templateOverrides: {
        start_months_ago: null,
        end_months_ago:   null
      },
      assignments: { companies: [], groups: [] },
      nextUpdate: null,
      typingDelay: 500,
      userAlreadyExist: null,
      dateRanges: [],
      periodHash: {
        day :    { required: [] },
        week:    { value: 1, field: "day_of_week",    required: ["week"]          },
        month:   { value: 2, field: "day_of_month",   required: ["month"]         },
        bimonth: { value: 2, field: "day_of_month",   required: ["month"]         },
        quarter: { value: 2, field: "day_of_quarter", required: ["quarter"]       },
        year:    { value: 1, field: "month",          required: ["year", "month"] },
      },
      weekDays: Array.from({ length: 7 }, (_, i) => i + 1),
      buttonsHash: {
        // User management
        add_report_recipient: {
          add_report:          "addReport",
          add_report_schedule: "redirectToReportSchedule"
        },
        add_user: {
          send_invite: "sendInvite",
          edit_user:   "redirectToUserEdition"
        },
        edit_user: {
          save_settings:      "updateUser",
          resend_invite:      "resendInvite",
          remove_permissions: "removePermissions"
        },

        // Report schedules
        schedule_report: {
          add_schedule: "handleAddSchedule",
        },

        // Report request
        report_request: {
          request: "sendRequest"
        }
      }
    };
  },
  props: ["edition", "context", "title", "contract", "type", "modals", "modalId", "userData", "users", "myData", "reportTemplates", "reportSchedules", "hideGroupCompanies", "reportSchedule"],
  components: { Multiselect, DatePicker },
  created() {
    if (this.userData) {
      this.user  = Utils.deepClone(this.userData);
      ["email", "first_name", "surname"].forEach(f => this[f] = this.userData[f]);
      this.assignments = Utils.deepClone(this.user.assignments);
      if (this.reportTemplates) this.reportTemplate = this.reportTemplates.find(r => r.id == this.userData.report_template_id);
      this.readTemplateOverrides(this.reportSchedule?.template_overrides);
      Object.keys(this.periodHash).forEach(m => {
        if (this.periodHash[m].value)
          this.periodHash[m].value = this.reportSchedule?.[this.periodHash[m].field] || this.periodHash[m].value
      })
    }
  },
  methods: {
    performAction(action) {
      this[this.buttonsHash[this.type][action]]();
    },

    // add_report_recipient
    async addReport(justAdd = true) {
      Utils.setLoading.bind(this)(true);
      try {
        let response = await axios.post(`/v3/contracts/${this.contract.id}/users`, {
          invite:      false,
          company_ids: this.assignments.companies.map(c => c.id),
          group_ids:   this.assignments.groups.map(g => g.id),
          user:        { email: this.email }
        });
        this.user = response.data.data;
        this.$store.commit("users/saveUsers", { users: [this.user], params: { contract_id: this.contract.id } })
        if (justAdd) this.success("add_report");
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    async redirectToReportSchedule() {
      await this.addReport(false);
      let authParams = Utils.slice(this.$route.query, Utils.authParams);
      this.$router.push({ name: "reports_schedules", query: { ...authParams, uid: this.user.id } });
    },

    // add_user
    async sendInvite() {
      Utils.setLoading.bind(this)(true);
      try {
        let response = await axios.post(`/v3/contracts/${this.contract.id}/users`, {
          invite:      true,
          company_ids: this.assignments.companies.map(c => c.id),
          group_ids:   this.assignments.groups.map(g => g.id),
          user:        { email: this.email },
          first_name:  this.first_name,
          surname:     this.surname
        });
        this.$emit("updateUsers", { user: response.data.data });
        this.success("send_invite");
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },

    // edit_user
    async updateUser(msg = "update_user") {
      Utils.setLoading.bind(this)(true);
      try {
        let response = await axios.put(`/v3/contracts/${this.contract.id}/users/${this.user.id}`, {
          company_ids: this.assignments.companies.map(c => c.id),
          group_ids:   this.assignments.groups.map(g => g.id),
          user:        { email: this.email },
          first_name:  this.first_name,
          surname:     this.surname
        });
        this.user = response.data.data;
        this.$emit("updateUsers", { user: this.user, action: msg });
        this.success(msg);
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    async resendInvite() {
      Utils.setLoading.bind(this)(true);
      try {
        if (this.user.type != "Report Recipient") {
          let response = await Swal.fire({
            title: this.$t("user_management.users.alert.are_you_sure"),
            text:  this.$t("user_management.users.alert.resend_invite_info"),
            icon: "question",
            showCancelButton:  true,
            showConfirmButton: true,
            allowOutsideClick: false
          });
          if (!response.isConfirmed) return;
        }
        await axios.post(`/v3/users/${this.user.id}/invite`, {
          subscription_id: this.contract.id
        });
        this.success("send_invite");
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },
    async removePermissions() {
      let response = await Swal.fire({
        title: this.$t("user_management.users.alert.are_you_sure"),
        text:  this.$t("user_management.users.alert.remove_permissions_info"),
        icon: "question",
        showCancelButton:  true,
        showConfirmButton: true,
        allowOutsideClick: false
      });
      if (!response.isConfirmed) return;
      this.assignments = { companies: [], groups: [] };
      this.updateUser("remove_permissions");
    },

    // schedule_report
    async handleAddSchedule() {
      if ((this.reportSchedules || []).length && this.conflictReportSchedule) {
        let response = await Swal.fire({
          title: this.$t("user_management.users.alert.are_you_sure"),
          text:  this.$t("user_management.users.alert.duplicate_report_schedule_info"),
          icon: "info",
          showCancelButton:  true,
          showConfirmButton: true,
          allowOutsideClick: false
        });
        if (!response.isConfirmed) return;
      }
      this.addSchedule();
    },
    async addSchedule() {
      Utils.setLoading.bind(this)(true);
      try {
        let periodInput = null;
        if (this.reportTemplate.period) {
          periodInput = this.periodHash[this.reportTemplate.period].required.reduce((res, curr) => {
            res[this.periodHash[curr].field] = this.periodHash[curr].value;
            return res;
          }, {});
        }
        let method = "post";
        let url = "/v3/reports/schedules";
        if (this.reportSchedule?.id) {
          method = "put";
          url = `${url}/${this.reportSchedule.id}`;
        }
        const templateOverrides = Object.entries(this.templateOverrides).reduce((res, [k, v]) => {
          if (v && v != this.reportTemplate[k]) res = { ...res, [k]: v };
          return res;
        }, {});
        const response = await axios[method](url, {
          contract_id: this.contract.id,
          user_id:     this.user.id,
          company_ids: this.assignments.companies.map(c => c.id),
          group_ids:   this.assignments.groups.map(g => g.id),
          report_template_id: this.reportTemplate?.id,
          ...periodInput,
          ...((templateOverrides) && { template_overrides: templateOverrides })
        });
        const schedule = {
          ...response.data.data,
          contract_id: this.contract.id,
          report_template_id: this.reportTemplate?.id,
          companies: response.data.data?.companies || this.assignments.companies || [],
          groups: response.data.data?.groups || this.assignments.groups || [],
          user_id: this.user.id,
          user: { ...this.user, name: this.user.full_name },
          template: this.reportTemplate,
          period: this.reportTemplate.period
        };
        let index = this.reportSchedules.findIndex(s => s.id == schedule.id);
        if (index != -1) this.reportSchedules[index] = { ...this.reportSchedules[index], ...schedule };
        else this.reportSchedules.push(schedule);
        this.success(this.edition ? "update_schedule" : "add_schedule");
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },

    // report_request
    async sendRequest() {
      Utils.setLoading.bind(this)(true);
      try {
        let promises = [];
        let baseParams = {
          contract_id: this.contract.id,
          user_id:     this.user.id,
          company_ids: this.assignments.companies.map(c => c.id),
          group_ids:   this.assignments.groups.map(g => g.id),
          identifier:  this.reportTemplate.identifier,
          report_template_id: this.reportTemplate?.id
        };
        this.dateRanges.forEach(dateRange => {
          promises.push(axios.post("/v2/report-requests", {
            ...baseParams,
            start_date: dateRange[0],
            end_date:   dateRange[1]
          }));
        });
        await Promise.all(promises);
        this.success("send_requests");
      } catch (err) { this.error(err); }
      Utils.setLoading.bind(this)(false);
    },

    // utilities
    editAssignments(ev, parent) {
      // method for checking/unchecking the checkboxes
      let value    = JSON.parse(ev.target.value);
      let isAdd    = ev.target.checked;
      let isGroup  = !!value.companies;

      // add group/company to assignments
      if (isAdd) {
        if (isGroup) {
          let group = this.assignments.groups.find(g => g.id == value.id);
          if (!group) {
            this.assignments.groups.push(value);
            let companiesFromGroup = value.companies.map(c => c.id);
            let companiesSelected  = this.assignments.companies.map(c => c.id);
            let intersections = companiesSelected.filter(c => companiesFromGroup.includes(c));
            intersections.forEach(companyId => {
              let intersectionIndex = this.assignments.companies.findIndex(c => c.id == companyId);
              if (intersectionIndex != -1) this.assignments.companies.splice(intersectionIndex, 1);
            });
          }
        }
        else if (!parent) {
          // if it is a company and it is not associated to a group
          let company = this.assignments.companies.find(c => c.id == value.id);
          if (!company) this.assignments.companies.push(value);
        }
        else if (parent) {
          // if it is a company associated to a group
          let group = this.assignments.groups.find(g => g.id == parent.id);
          if (!group) {
            // group not selected
            let companiesFromGroup = parent.companies.map(c => c.id);
            let companiesSelected  = this.assignments.companies.map(c => c.id);
            let diff = companiesFromGroup.filter(c => !companiesSelected.includes(c));
            if (diff.length == 1 && diff[0] == value.id) {
              // if the only company from the group that is not selected is the one being selected
              this.assignments.groups.push(parent);
              companiesFromGroup.forEach(companyId => {
                let companyIndex = this.assignments.companies.findIndex(c => c.id == companyId);
                if (companyIndex != -1) this.assignments.companies.splice(companyIndex, 1);
              });
            } else {
              let company = this.assignments.companies.find(c => c.id == value.id);
              if (!company) this.assignments.companies.push(value);
            }
          }
        }
      }

      // remove group/company from assignments
      else {
        if (isGroup) {
          let groupIndex = this.assignments.groups.findIndex(g => g.id == value.id);
          if (groupIndex != -1) this.assignments.groups.splice(groupIndex, 1);
        }
        else if (!parent) {
          // if it is a company and it is not associated to a group
          let companyIndex = this.assignments.companies.findIndex(c => c.id == value.id);
          if (companyIndex != -1) this.assignments.companies.splice(companyIndex, 1);
        }
        else if (parent) {
          // if it is a company associated to a group
          let groupIndex = this.assignments.groups.findIndex(g => g.id == parent.id);
          if (groupIndex != -1) {
            // group is selected
            this.assignments.groups.splice(groupIndex, 1);
            let companiesStillSelected = parent.companies.filter(c => c.id != value.id);
            companiesStillSelected.forEach(company => {
              if (!this.assignments.companies.find(c => c.id == company.id)) this.assignments.companies.push(company);
            });
          } else {
            // group is not selected
            let companyIndex = this.assignments.companies.findIndex(c => c.id == value.id);
            if (companyIndex != -1) this.assignments.companies.splice(companyIndex, 1);
          }
        }
      }
    },
    checked(group, company) {
      if (group && !company) return !!this.assignments.groups.find(g => g.id == group.id);
      if (group &&  company) {
        let isGroupSelected = this.assignments.groups.find(g => g.id == group.id);
        if (isGroupSelected) return !!isGroupSelected.companies?.find(c => c.id == company.id);
        return this.assignments.companies.find(c => c.id == company.id);
      }
      return !!this.assignments.companies.find(c => c.id == company.id);
    },
    buttonStyle(action) {
      return action == "remove_permissions" ? "btn-danger" : "btn-primary";
    },
    showButton(action) {
      if (this.type == "add_user" && action == "send_invite" && this.userAlreadyExist) return false;
      if (this.type == "add_user" && action == "edit_user"   && !this.userAlreadyExist) return false;
      return true;
    },
    buttonLabel(action) {
      if (action == "resend_invite" && this.user.type == "Report Recipient") return this.$t("user_management.users.send_invitation");
      return this.$t(`user_management.users.${action}`);
    },
    success(action) {
			Swal.fire(this.$t("general.success"), this.$t(`user_management.users.alert.${action}_success`), "success");
      this.modals[this.modalId] = false;
      Utils.setLoading.bind(this)(false);
		},
    error(error) {
      Swal.fire(this.$t("general.error"), this.$t("general.contact_support") + JSON.stringify(error.response?.data), "error");
      this.modals[this.modalId] = false;
      Utils.setLoading.bind(this)(false);
    },
    clearSelection(fields) {
      if (fields.includes("date")) this.dateRanges  = [];
      if (fields.includes("assignments")) this.assignments = { groups: [], companies: [] };
      if (fields.includes("template")) this.reportTemplate = null;
    },
    userLabel(user) {
      if (user.full_name) return `${user.full_name} <${user.email}>`;
      return user.email;
    },
    showField(field) {
      let allowedFields = ["email", "name", "users", "past_reports", "report_templates", "give_access_text"];
      if (this.context == "user_management") allowedFields = ["email", "name", "give_access_text"];
      if (this.context == "report_schedule") allowedFields = ["users", "report_templates", "date", "start_months_ago", "end_months_ago"];
      if (this.context == "report_request")  allowedFields = ["users", "past_reports", "report_templates"];
      return allowedFields.includes(field);
    },
    emailInput() {
      this.nextUpdate = Date.now() + this.typingDelay;
      setTimeout(() => this.searchForUser(), this.typingDelay);
    },
    async searchForUser() {
      if (this.nextUpdate > Date.now() || !this.users) return;
      this.userAlreadyExist = this.users.find(u => u.email == this.email);
    },
    redirectToUserEdition() {
      this.modals.add_user = false;
      this.modals[`user_${this.userAlreadyExist.id}`] = true;
    },
    dateUpdate(dateRange) {
      if (!dateRange.checked) {
        let index = this.dateRanges.findIndex(d => JSON.stringify(d) == JSON.stringify(dateRange.value));
        if (index != -1) this.dateRanges.splice(index, 1);
      }
      else this.dateRanges.push(dateRange.value);
    },
    max(field) {
      if (field == "week")    return 7;
      if (field == "year")    return 12;
      if (field == "quarter") return 90;
      if (field == "bimonth") return 60;
      if (field == "month" && this.reportTemplate?.period == "year") {
        if (this.periodHash.year.value == 2) return 28;
        return dayjs(`${dayjs().year()}-${this.periodHash.year.value}`).daysInMonth();
      }
      return 31;
    },
    rules(field) {
      return `required|min_value:1|max_value:${this.max(field)}`;
    },
    dayOfWeek(day) {
      return this.$t(`general.week_days.${dayjs().day(day).format("dddd").toLowerCase()}`);
    },
    readTemplateOverrides(obj = {}) {
      Object.keys(this.templateOverrides).forEach(k => this.templateOverrides[k] = obj[k] || this.reportTemplate?.[k]);
    }
  },
  computed: {
    isValidEmail() {
      return Utils.validEmail(this.email);
    },
    allowEmailEdition() {
      if (this.type == "edit_user") return this.user.id == this.myData.id;
      return true;
    },
    showCompaniesSelector() {
      return !this.showField("report_templates") || this.reportTemplate?.based_on == "companies";
    },
    showExpandGroupButton() {
      if (this.showField("report_templates")) return this.reportTemplate?.based_on == "companies";
      return true;
    },
    validInputDate() {
      if (this.reportTemplate?.id) {
        if (!this.reportTemplate.period) return true;
        return this.periodHash[this.reportTemplate.period].required.reduce((res, curr) => {
          let value = this.periodHash[curr].value;
          if (value < 1 || value > this.max(curr)) res = false;
          return res;
        }, true);
      }
      return false;
    },
    validFields() {
      if (this.userAlreadyExist) return true;
      if (this.type == "schedule_report") return this.reportTemplate?.id && (this.assignments.companies.length || this.assignments.groups.length) && this.validInputDate;
      if (this.type == "report_request") return this.dateRanges.length && this.reportTemplate?.id && (this.assignments.companies.length || this.assignments.groups.length);
      return this.isValidEmail && (this.assignments.companies.length || this.assignments.groups.length);
    },
    conflictReportSchedule() {
      let templates = (this.reportSchedules || []).filter(s => s.id == this.reportTemplate?.id);
      if (templates.length) {
        let res = false;
        templates.forEach(template => {
          let companiesDiff = this.assignments.companies.filter(c => !template.companies.map(a => a.id).includes(c.id));
          let groupsDiff = this.assignments.groups.filter(c => !template.groups.map(a => a.id).includes(c.id));
          if ((this.assignments.companies.length && !companiesDiff?.length) || (this.assignments.groups.length && !groupsDiff?.length)) res = true;
        });
        return res;
      }
      return false;
    },
    assignmentsOptions() {
      if (!this.user?.id || this.context == "user_management") return this.myData.assignments;
      if (this.user?.options && /report_schedule|report_request/.test(this.context)) return this.user.options;
      return this.user.assignments;
    }
  },
  watch: {
    reportTemplate: {
      handler: function () {
        if (this.reportTemplate) this.readTemplateOverrides(this.reportTemplate);
      },
      deep: true
    }
  }
}
</script>

<template>
  <div class="p-4 d-flex flex-column">
    <h3> {{ title }} </h3>

    <!-- E-mail field -->
    <div v-if="showField('email')" class="form-group mt-4">
      <label class="form-label">{{ $t("user_management.users.add_email") }}</label>
      <div v-if="allowEmailEdition" class="position-relative">
        <input type="email" class="form-control" :class="{ 'valid': isValidEmail, 'invalid': !isValidEmail }" :placeholder="$t('user_management.users.add_email_placeholder')" v-model="email" :state="false" @input="emailInput" />
      </div>
      <div v-else>{{ email }}</div>
    </div>

    <div v-if="showField('name')" class="mb-4">
      <div  class="form-group">
        <label class="form-label">{{ $t("user_management.users.first_name") }}</label>
        <input type="text" class="form-control" :placeholder="$t('user_management.users.first_name_placeholder')" v-model="first_name" />
      </div>

      <div  class="form-group">
        <label class="form-label">{{ $t("user_management.users.surname") }}</label>
        <input type="text" class="form-control" :placeholder="$t('user_management.users.surname_placeholder')" v-model="surname" />
      </div>
    </div>

    <span v-if="showField('give_access_text') && (assignmentsOptions.companies.length || assignmentsOptions.groups.length)">
      <label class="form-label">{{ $t("user_management.users.give_access") }}</label>
      ({{ $t("user_management.users.give_access_tip") }})
    </span>

    <!-- Users -->
    <div v-if="showField('users') && (users || []).length" class="d-flex flex-column">
      <label class="mt-2 form-label">{{ $t("reports.select_user") }}</label>
      <multiselect :multiple="false" v-model="user" :options="users" :searchable="true" :custom-label="u => userLabel(u)" track-by="id" :showLabels="false" :placeholder="$t('filter.select_option')" @select="clearSelection(['date', 'assignments', 'template'])" @remove="clearSelection(['date', 'assignments', 'template'])"></multiselect>
    </div>

    <div v-else-if="showField('users') && user && user.id" class="d-flex flex-column">
      <label class="mt-2 mb-0 form-label">{{ $t("user_management.users.user") }}</label>
      <div>{{ userLabel(user) }}</div>
    </div>

    <!-- Report Templates -->
    <div v-if="showField('report_templates') && reportTemplates && reportTemplates.length" class="d-flex flex-column mt-3">
      <label class="mt-2 form-label">{{ $t("user_management.users.select_report") }}</label>
      <multiselect :multiple="false" v-model="reportTemplate" :options="reportTemplates" :searchable="true" label="name" track-by="id" :showLabels="false" :placeholder="$t('filter.select_option')" @select="clearSelection(['date', 'assignments'])" @remove="clearSelection(['date', 'assignments'])" ></multiselect>
    </div>

    <!-- Past reports -->
    <div v-if="showField('past_reports') && reportTemplate && reportTemplate.period" class="mt-3">
      <label class="mt-2 form-label">{{ $t("reports.select_past_reports") }}</label>
      <div class="position-relative pt-3 mt-2">
        <DatePicker :reportTemplateId="reportTemplate.id" :period="reportTemplate.period" @updateDatePicker="dateUpdate" />
      </div>
    </div>

    <!-- Schedule month and/or day of the week|month|quarter -->
    <div v-if="showField('date') && reportTemplate && reportTemplate.period" class="mt-3 d-flex">
      <div v-for="f in periodHash[reportTemplate.period].required" :key="f" class="me-3">
        <label class="mt-2 form-label">{{ $t(`reports.date_input.${periodHash[f].field}`) }}</label>
        <multiselect v-if="f == 'week'" :multiple="false" v-model="periodHash[f].value" :options="weekDays" :custom-label="dayOfWeek" :showLabels="false" :placeholder="$t('filter.select_option')"></multiselect>
        <input v-else type="number" min="1" :max="max(f)" class="form-control" v-model="periodHash[f].value">
      </div>
    </div>

    <div v-if="showField('start_months_ago') && reportTemplate && reportTemplate.id" class="mt-3 d-flex">
      <div class="me-3">
        <label class="mt-2 form-label">{{ $t("reports.start_months_ago") }}</label>
        <input type="number" min="0" max="100" class="form-control" v-model="templateOverrides.start_months_ago">
      </div>
    </div>

    <div v-if="showField('end_months_ago') && reportTemplate && reportTemplate.id" class="mt-3 d-flex">
      <div class="me-3">
        <label class="mt-2 form-label">{{ $t("reports.end_months_ago") }}</label>
        <input type="number" min="0" :max="templateOverrides.start_months_ago || 0" class="form-control" v-model="templateOverrides.end_months_ago">
      </div>
    </div>

    <!-- Property/Groups selector section -->
    <div class="mt-3" style="max-height:35vh;overflow-y:scroll;">
      <!-- Groups -->
      <div v-if="assignmentsOptions.groups.length" class="d-flex flex-column">
        <label class="mt-2 form-label">{{ $t("user_management.users.groups") }}</label>
        <div v-for="group in assignmentsOptions.groups" :key="`${title}_group_${group.id}`" class="ms-3">
          <div class="d-flex align-items-center mb-1">
            <a v-if="!hideGroupCompanies && showExpandGroupButton" class="font-size-22 me-2 mb-2 chevron" data-bs-toggle="collapse" :href="`#group_${group.id}`" aria-expanded="false" :aria-controls="`group_${group.id}`">
              <i class="mdi mdi-chevron-down"></i>
            </a>
            <div class="custom-control custom-control-inline custom-checkbox b-custom-control-md">
              <input type="checkbox" :name="`${title}_group`" class="custom-control-input" :checked="checked(group, null)" :value="JSON.stringify(group)" :id="`${title}_group_${group.id}`" @input="editAssignments($event, null)" />
              <label class="custom-control-label form-label active font-weight-normal font-size-14" :for="`${title}_group_${group.id}`">{{ group.name }}</label>
            </div>
          </div>

          <div class="collapse" :id="`group_${group.id}`" v-if="!hideGroupCompanies && showExpandGroupButton">
            <div v-for="company in group.companies" :key="`${title}_group_${group.id}_${company.id}`" class="ms-5">
              <div class="ms-2 mb-1 custom-control custom-control-inline custom-checkbox b-custom-control-md">
                <input type="checkbox" :name="`${title}_group_${group.id}`" class="custom-control-input" :checked="checked(group, company)" :value="JSON.stringify(company)" @input="editAssignments($event, group)" :id="`${title}_group_${group.id}_${company.id}`" />
                <label class="custom-control-label form-label active font-weight-normal font-size-14" :for="`${title}_group_${group.id}_${company.id}`">{{ company.name }}</label>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Individual Properties -->
      <div v-if="showCompaniesSelector" class="d-flex flex-column mt-3">
        <label class="mt-2 form-label">{{ $t("user_management.users.individual") }}</label>
        <div v-for="company in assignmentsOptions.companies" :key="`${title}_individual_${company.id}`" class="ms-3 mb-1 custom-control custom-control-inline custom-checkbox b-custom-control-md">
          <a href="#" class="font-size-22 me-2 chevron" style="visibility: hidden; height: 0;">
            <i class="mdi mdi-chevron-down"></i>
          </a>
          <input type="checkbox" :name="`${title}_individual`" class="custom-control-input" :checked="checked(null, company)" :value="JSON.stringify(company)" @input="editAssignments($event, null)" :id="`${title}_individual_${company.id}`" />
          <label class="custom-control-label form-label active font-weight-normal font-size-14" :for="`${title}_individual_${company.id}`">{{ company.name }}</label>
        </div>
      </div>
    </div>

    <!-- Action Buttons -->
    <div class="footer-nav d-flex flex-column flex-sm-row justify-content-between mt-4" style="gap: 10px;">
      <button class="btn btn-outline-secondary me-2" @click="modals[modalId] = false;">
        {{ $t("surveys_list.cancel") }}
      </button>

      <div class="d-flex flex-column flex-sm-row" style="gap: inherit;">
        <template v-for="action in Object.keys(buttonsHash[type])" :key="action">
          <button v-if="showButton(action)" @click="performAction(action)" class="btn me-2" :disabled="!validFields" :class="buttonStyle(action)">
            {{ buttonLabel(action) }}
          </button>
        </template>
      </div>
    </div>
  </div>
</template>
