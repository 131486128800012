import store from '../state/store'
import axios from 'axios'

/*
import axiosRetry from 'axios-retry';
axios.defaults.timeout = 3 * 60 * 1000

axiosRetry(axios, { retries: 3, shouldResetTimeout: true, retryCondition: (error) => {
  // Fallback to default behavior
  const defaultRetryCondition = axiosRetry.isNetworkOrIdempotentRequestError(error);
  // Add timeout check
  return defaultRetryCondition || error.code === 'ECONNABORTED';
  },
  onRetry: (retryCount, error) => {
    console.log(`Retrying request, attempt ${retryCount},  error: `, error.message)
    //console.log('Request config:', requestConfig)
  },
})
*/

function axiosInit(axios) {
  axios.defaults.baseURL = process.env.VUE_APP_BASE_SERVER == undefined ? 'https://agora.olery.com' : process.env.VUE_APP_BASE_SERVER;
  axios.defaults.params  = {};
  axios.defaults.headers.post['Content-Type'] = 'application/json';
  axios.defaults.headers.put['Content-Type']  = 'application/json';

  axios.interceptors.request.use(config => {
    if (window.reports?.api) window.reports.api.activeRequests += 1

    if (store.getters.token()) config.params['auth_token']            = store.getters.token();
    if (store.getters.ep())    config.params['ep']                    = store.getters.ep();
    if (store.getters.code())  config.params['code']                  = store.getters.code();
    if (store.getters.overr_user()) config.params['override_user_id'] = store.getters.overr_user();
    return config;
  }, error => {
    if (window.reports?.api) window.reports.api.activeRequests -= 1
    return Promise.reject(error);
  });

  axios.interceptors.response.use(response => {
    if (window.reports?.api) window.reports.api.activeRequests -= 1
    return response;
  }, async error => {
    if (window.reports?.api) window.reports.api.activeRequests -= 1

    const originalRequest = error.config;
    if (error.response?.status == 401 && !originalRequest._retry && process.env.NODE_ENV == 'production') {
      originalRequest._retry = true;
      const new_ep = await axios.get("https://guest-experience.com/token.json", { mode: 'no-cors' });
      if (new_ep.ep) store.commit('SAVE_TOKEN', { ep: new_ep.ep });
      return axios(originalRequest);
    }
    return Promise.reject(error);
  })
}

export {axiosInit}

axiosInit(axios)
