<script>
import Utils     from "@/js/utils";
import sources   from '@/data/sources'
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";
import dayjs     from "dayjs";

import quarterOfYear from "dayjs/plugin/quarterOfYear";
dayjs.extend(quarterOfYear);

export default{
  data() {
    return {
      loading: true,
      error: false,
      themeColor: "#90a0cb",
      data:      {},
      cols:      [],
      groups:    [],
      companies: [],
      segments:  [],
      promises:  [],
      groupByValuesMap: {
        numerical_rating:      'ratings',
        sentiment_rating:      'opinions',
        tes_rating:            'tes',
        reviews_count:         'ratings',
        sentiment_reviews_count: 'opinions',
        mentions_count:        'opinions',
        // responses_count:       'with_responses', // special
        // response_rate:         'rate',
        // respondable_responses: 'respondable'
        sentiment_count:       'opinions'
      },
      valuesMap: {
        numerical_rating:      'value',
        sentiment_rating:      'sentiment_score',
        tes_rating:            'score',
        reviews_count:         'review_count',
        sentiment_reviews_count: 'review_count',
        mentions_count:        'opinions_count',
        responses_count:       'with_responses',
        response_rate:         'rate',
        respondable_responses: 'respondable'
      },
      kpiKeys: { companies: 'company_ids', groups: 'group_ids' },
      localePaths: {
        composition:      'travel_composition',
        country:          'countries',
        language:         'locales',
        property_type:    'property_types',
        source:           'source',
        travel_type:      'travel_type',
        numerical_rating: 'topics',
        sentiment_rating: 'topics',
        tes_rating:       'topics',
      },
      dateFormatTemplates: {
        day:     "DD",
        week:    "DD MMM YY",
        month:   "MMMM YYYY",
        quarter: "[Q]Q YYYY",
        year:    "YYYY"
      },
      polarities:  ["positive", "neutral", "negative"],
      competitors: [],
      othersRow:   [],
      averageWeightFields: {
        numerical_rating: 'reviews_count',
        sentiment_rating: 'mentions_count',
        tes_rating:       'reviews_count',
      }
    }
  },
  props: ['contract', 'settings', 'group_id', 'group_ids', 'company_id', 'start_date', 'end_date', 'template'],
  components: { NotLoaded, Loading },
  async mounted() {
    this.promises = []
    if (this.settings.use_competitors && this.template.based_on == 'companies') await this.loadCompetitors()
    else await this.loadRows()

    this.cols = []
    this.settings.columns.forEach(c => this.cols.push({
      ...c,
      ...((c.group_by_period || this.isGroupedBySegment(c.type)) ? { labels: [] } : { labels: [{ key: c.type, label: this.translate.bind(this)(c) }] })
    }));

    if (/companies|groups/.test(this.settings.row_data))
      this.loadByRow()
    else {
      const segment = this.settings.segment
      this.settings.columns.forEach((c, i) => {
        this.loadById(this[this.kpiKey], c, segment, i)
      })
    }

    await Promise.all(this.promises)
    this.loading = false;
  },
  methods: {
    addCol(column, colIndex, key, type, groupedBy) {
      this.cols[colIndex].labels ??= [];

      if (groupedBy) {
        let col = this.cols[colIndex].labels.find(l => l.key == key);
        if (!col) {
          if (column.col_limit && this.cols[colIndex].labels.length == column.col_limit) return;
          this.cols[colIndex].labels.push({
            key:       key,
            label:     this.formatLabel({ ...column, type: groupedBy }, key),
            subLabels: column.data_to_show.map(d => ({ key: d, label: this.$t(`overall_chart.${d}`), coloured: !!column.coloured_columns?.find(c => c == d) }))
          });
        }
      }
      if (this.cols[colIndex].labels.find(l => l.key == key)) return;

      if ((column.group_by_period) && type == "data") {
        this.cols[colIndex].labels.push({
          key:       groupedBy || key,
          label:     this.formatLabel(column, key)
        });
      }
      if (column.show_trend && type == "trend") {
        this.cols[colIndex].labels.push({
          key:   key,
          label: column.trend_label || this.$t(`reports.trend_with_range`, { t: column.trend_range?.toUpperCase() })
        });
      }
      if (column.show_benchmark && type == "benchmark") {
        this.benchObjects(column).forEach(b => {
          this.cols[colIndex].labels.push({
            key:   key,
            label: b?.name || column.benchmark_based_on
          });
        });
      }
    },
    isGroupedBySegment(colType) {
      return /composition|country|language|source|travel_type|subratings/.test(colType);
    },
    formatLabel(column, key) {
      let res = key;
      if (column.group_by_period)       res = dayjs(key).format(this.dateFormatTemplates[column.period]);
      if (column.type == "country")     res = this.$t(`countries.${key}`);
      if (column.type == "language")    res = this.$t(`locales.${key}`);
      if (column.type == "source")      res = sources[key]?.friendly_name || key;
      if (column.type == "composition") res = this.$t(`travel_composition.${key}`);
      if (column.type == "travel_type") res = this.$t(`travel_type.${key}`);
      if (column.type == "property_type") res = this.$t(`property_types.${key}`);
      if (column.type == "subratings")  res = this.$t(`topics.${key}`);
      return res;
    },
    translate(column) {
      if (column.label && column.label.trim().length > 0) return column.label
      let t = this.$t || this.$parent.$t
      return t(`overall_chart.${column.type}`)
    },
    loadCompetitors() {
      return this.$store.dispatch("companies/fetch", { contract_id: this.contract.id, with_competitors: true }).then(data => {
        this.competitors = data.find(c => c.id == this.company_id)?.competitors || [];
      }).catch(() => this.error = true);
    },

    async loadById(id, column, segment, colIndex, groupedBy) {
      //TODO: load data for segments on columns
      const per = column.group_by_period ? column.period : undefined;
      let start_date = this.start_date;
      let end_date   = this.end_date;
      Object.keys(this.settings.filters || {}).forEach(k => { if (!this.settings.filters[k]?.length) delete this.settings.filters[k] })

      if (per) start_date = dayjs(end_date).subtract(column.period_qtt || 5, per).add(1, "day").format("YYYY-MM-DD");

      if (/numerical_rating|sentiment_rating|tes_rating|sentiment_reviews_count|reviews_count|mentions_count|sentiment_count/.test(column.type)) {
        if (this.settings.row_data == "segments" && /_rating/.test(this.settings.segment)) segment = null
        let params = {
          [this.paramsKey]: id,
          ratingType:       column.type,
          start_date:       start_date,
          end_date:         end_date,
          segment:          segment,
          ctx:              'stats',
          group_by:         'ratings',
          ...this.settings.filters,
          per
        }
        this.promises.push(this.$store.dispatch("kpis/fetch", params)
          .then(data => this.setData(colIndex, column, id, data, per, groupedBy, params))
          .catch((e) => { this.error = true; throw e })
        );

        if (column.show_benchmark && !column.group_by_period && !groupedBy) { // TODO: support group by period
          this.benchObjects(column).forEach(b => {
            let p = { ...params, [this.paramsKey]: null, [this.kpiKeys[column.benchmark_based_on]]: b.id };
            this.promises.push(this.$store.dispatch("kpis/fetch", p).then(data => {
              const d = column.type == 'tes_rating' ? data?.[0]?.tes : data;
              if (!d) return;
              const f = d.date || d.segment || d.topic || this.groupByValuesMap[column.type] || column.type;
              const k = `benchmark_${f}`;
              this.setRowData(colIndex, column, id, 'benchmark', d, k, f);
            }).catch(() => this.error = true));
          })
        }
      } else if (/responses_count|response_rate|respondable_responses/.test(column.type)) {
        let params = {
          [this.paramsKey]: id,
          start_date:       start_date,
          end_date:         end_date,
          segment:          segment,
          subscription_ids: this.contract.id,
          trends:           column.trend_range,
          ...this.settings.filters,
          per
        }
        this.promises.push(this.$store.dispatch("kpis/response_rate", params)
          .then(data => this.setData(colIndex, column, id, data, per, groupedBy, params))
          .catch(() => this.error = true)
        );

        if (column.show_benchmark && !column.group_by_period && !groupedBy) {
          this.benchObjects(column).forEach(b => { // TODO: support group by period
            let p = { ...params, [this.paramsKey]: null, [this.kpiKeys[column.benchmark_based_on]]: b.id }
            this.promises.push(this.$store.dispatch("kpis/response_rate", p).then(data => {
              const f = data.date || data.segment || data.topic || this.groupByValuesMap[column.type] || column.type;
              const k = `benchmark_${f}`;
              this.setRowData(colIndex, column, id, 'benchmark', data, k, f)
            }).catch(() => this.error = true));
          })
        }
      }
    },
    async loadByRow() {
      this.rowsData.forEach(d => {
        this.settings.columns.forEach((c, i) => {
          if (this.isGroupedBySegment(c.type)) {
            c.data_to_show.forEach(ds => this.loadById(d.id, { ...c, type: ds }, c.type, i, c.type));
          } else {
            this.loadById(d.id, c, null, i);
          }
        })
      })
    },
    groupBySegment(data = []) {
      let res = data.reduce((res, curr) => {
        const seg = curr.date;
        if (res[seg]) res[seg].push(curr);
        else res[seg] = [curr];
        return res;
      }, {});
      return Object.entries(res).map(([k, v]) => ({ date: k, ratings: v }));
    },
    setData(colIndex, column, id, data, per, groupedBy, params) {
      if (per || groupedBy) {
        let rows = [...data].sort((a, b) => dayjs(b.date).diff(dayjs(a.date))); // change this
        if (groupedBy == "subratings") rows = rows.flatMap(r => r?.[this.groupByValuesMap[column.type]]).filter(n => n);
        if (this.settings.row_data == "segments") rows = this.groupBySegment(rows);

        rows.forEach(r => {
          const d = r?.[this.groupByValuesMap[column.type]] || r?.values || r;
          if (!d) return;
          const k = r.date || r.segment || r.topic || column.type;
          this.setRowData(colIndex, column, id, 'data', d, k, k, groupedBy);
          // this.setTrend(params, i, column, id, d); // TODO: support trends by period
        });
      } else {
        const d = data;
        if (!d) return;
        const k = d.date || d.segment || d.topic || this.groupByValuesMap[column.type] || column.type;
        this.setRowData(colIndex, column, id, 'data', d, k, k);
        this.setTrend(params, colIndex, column, id, d, k, k);
      }
    },
    setRowData(colIndex, column, id, type, kpi, key, field, groupedBy) {
      if (column.group_by_period || groupedBy || type == "benchmark")
        this.addCol(column, colIndex, key, type == "benchmark" ? "benchmark" : "data", groupedBy)

      if (this.settings.row_data == 'segments') {
        kpi.forEach(data => {
          let k = data.segment || data.topic
          let v = data[field] || data.current || data
          let name;

          if (!k) return
          if (this.settings.segment == 'source') {
            name = sources[k].friendly_name
          } else
            name = this.$t(`${this.localePaths[this.settings.segment]}.${k}`)

          this.segments[k] ??= { id: k, name: name }

          return this.setRow(k, v, colIndex, column, type, key, field, groupedBy)
        })
      } else {
        let v = kpi.current || kpi
        return this.setRow(id, v, colIndex, column, type, key, field, groupedBy)
      }
    },
    getValue(v, column) {
      const key = this.valuesMap[column.type]
      if (typeof v.find == "function") {
        const topic = column.segmentOption || "overall";
        v = v.find(j => j.topic == topic);
      }

      let value = (v || {})[key]
      if (column.type == "numerical_rating") value = value / 10
      if (column.type == "response_rate")    value = Utils.round(value * 100)
      if (column.type == "sentiment_count")
        value = v.opinions_count ? this.polarities.reduce((res, pol) => {
          res[pol] = Utils.round(v[`${pol}_opinions`] * 100 / v.opinions_count, 2);
          return res;
        }, {}) : null;
      if (/value|score|sentiment_score/.test(key)) value = Utils.round(value, 1) || null
      return value
    },
    setRow(k, v, i, column, type, key, field, groupedBy) {
      this.data[k]    ??= []
      this.data[k][i] ??= { column, benchmark: [] }
      let row = this.data[k][i]
      let value = this.getValue(v, column)
      let weight;
      if (this.averageWeightFields[column.type]) weight = this.getValue(v, { type: this.averageWeightFields[column.type] });
      if (groupedBy) value = { ...row.values?.[field], [column.type]: value, [key]: value }
      row.values = { ...row.values, [column.type]: value, [key]: value, weight }
      return row
    },
    calculateTrend(column, index, id, kpis, key) {
      if (this.settings.row_data == 'segments') {
          kpis.forEach(kpi => {
            let k = kpi.segment || kpi.topic
            if (!k || !this.data[k] || !this.data[k][index]) return
            const row    = this.data[k][index]
            const v      = kpi[key] || kpi.previous || kpi
            const value  = this.getValue(v, column)
            let   trend  = Utils.calcTrend(row.values[column.type], value)


            if (value || value == 0) row.values = { ...row.values, [`trend_${key}`]: { prev: value, trend } }
          })
        } else {
          const value  = this.getValue(kpis, column)
          const row    = this.data[id][index]
          let   trend  = Utils.calcTrend(row.values[column.type], value)

          if (value || value == 0) row.values = { ...row.values, [`trend_${key}`]: { prev: value, trend } }
        }
    },
    setTrend(params, index, column, id, data, key) {
      if (!column.show_trend) return
      this.addCol(column, index, `trend_${key}`, "trend");

      if (/responses_count|response_rate|respondable_responses/.test(column.type)) {
        this.calculateTrend(column, index, id, data, key)
      } else {
        const tp = Utils.timePeriods.previous(params.start_date, params.end_date, column.trend_range)
        let p = { ...params, ...tp }
        this.$store.dispatch("kpis/fetch", p).then(((column, kpis) => {
          this.calculateTrend(column, index, id, kpis, key)
        }).bind(undefined, column)).catch(() => this.error = true)
      }
    },
    benchObjects(column) {
      let field = `bench_${this.kpiKeys[column.benchmark_based_on]}`
      return column[field].map(id => this[column.benchmark_based_on].find( o => o.id == Number(id) )).filter( n => n )
    },
    async loadRows() {
      const rows = [this.settings.row_data] + this.settings.columns.map(c => c.benchmark_based_on)
      if (rows.includes('companies') || rows.includes('groups')) await this.loadGroups();
    },
    loadGroups() {
      return this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
        this.groups = groups;
        if (this.template.based_on == "companies") {
          this.companies = groups.flatMap(g => g.companies);
          this.companies = [...new Map(this.companies.map(g => [g.id, g])).values()];
        } else {
          this.companies = groups.find(g => g.id == this.group_id)?.companies || [];
        }
      }).catch(() => this.error = true);
    },
    ratingClass(value) {
      return Utils.ratings.toCss(value);
    },
    highlightClass(value, from) {
      return value >= from ? "highlight-rating" : "";
    },
    trendColor(trend) {
      if (trend > 0) return 'trend-green'
      if (trend < 0) return 'trend-red'
      return 'trend-grey'
    },
    value(val, key, subKey) {
      let value = val;
      if (key) value = val[key];
      if (subKey) value = value?.[subKey]
      if (!value && value != 0) return "-";
      return value;
    },
    sortByGroupedSegment(data = [], field, colIndex, col) {
      // find the col with the highest value and use this col to order the rows
      const key = Object.entries(this.data).reduce((res, [,cols]) => {
        Object.entries(cols[colIndex].values).forEach(([k, a]) => {
          const val = a[field] || a;
          if (parseFloat(res.val) < parseFloat(val)) {
            res.val = a[field];
            res.key = k;
          }
        });
        return res;
      }, { val: 0, key: "" }).key;

      data = data.sort((a, b) => {
        let vA = this.data[a.id]?.[colIndex]?.values?.[key];
        let vB = this.data[b.id]?.[colIndex]?.values?.[key];
        if (!col.group_by_period) {
          vA = vA?.[field];
          vB = vB?.[field];
        }
        if (vA == "-" || !vA) return -1;
        if (vB == "-" || !vB) return  1;
        return vA - vB;
      });
      return data.filter(r => this.data[r.id]?.map(d => Object.keys(d.values).length)?.filter(n => n)?.length);
    },
    sortByCol(data = [], field, colIndex) {
      data = data.sort((a, b) => {
        const vA = this.data[a.id]?.[colIndex]?.values?.[field];
        const vB = this.data[b.id]?.[colIndex]?.values?.[field];
        if (vA == "-" || !vA) return -1;
        if (vB == "-" || !vB) return  1;
        return vA - vB;
      });
      return data.filter(r => this.data[r.id]?.map(d => d.values[field])?.filter(n => n)?.length);
    },
    columns(row) {
      if (row.id == "others") return this.othersRow;
      return this.data[row.id] || [{ values: {} }];
    },
    setOthersRow(rows) {
      const data = Utils.slice(this.data, rows.map(r => r.id));
      const othersRow = this.cols.map(c => ({ column: c, benchmark: [], values: { [c.type]: 0 } }));
      this.cols.forEach((c, cIndex) => {
        let total = 0;
        c.labels.forEach(l => { // col
          const weightField = this.averageWeightFields[l.key];
          Object.values(data).forEach(row => {
            row.forEach(r => {
              const value = Number(r.values[l.key] || 0);
              const weight = r.values.weight;
              if (weight) {
                total += weight;
                othersRow[cIndex].values[l.key] += weight * value;
              }
              else othersRow[cIndex].values[l.key] += value;
            });
          });
          if (weightField) othersRow[cIndex].values[l.key] = Utils.round(othersRow[cIndex].values[l.key] / total);
        });
      });
      this.othersRow = othersRow;
    }
  },
  computed: {
    rowLabel() {
      if (this.settings.use_competitors && this.template.based_on == "companies") return this.$t("reports.competitors");
      if (/companies|groups/.test(this.settings.row_data)) return this.$t(`reports.settings_modal.row_types.${this.settings.row_data}`);
      return this.$t(`reports.settings_modal.segment_options.${this.settings.segment}`);
    },
    rowsData() {
      if (this.settings.row_data == 'segments')
        return Object.values(this[this.settings.row_data])
      if (this.settings.use_competitors && this.template.based_on == "companies") return this.competitors;
      return this[this.settings.row_data]
    },
    filteredRows() {
      let data = Utils.deepClone(this.rowsData);
      data = data.filter(d => !(this.settings.exclude_ratings || []).includes(d.id)); // filter out ratings
      let orderBy = this.settings.order_by;

      if (orderBy == "header") {
        data = Utils.sortField(data, "name");
      } else if (orderBy) {
        const i   = this.settings.columns.findIndex(c => c.type == orderBy);
        const col = this.settings.columns[i];

        if (col) {
          let field = orderBy;
          if (this.isGroupedBySegment(col.type) || col.group_by_period) {
            if (!col.group_by_period) field = col.data_to_show?.length == 1 ? col.data_to_show[0] : this.settings.order_by_option;
            data = this.sortByGroupedSegment(data, field, i, col);
          } else {
            data = this.sortByCol(data, field, i);
          }
        }
      }

      if (this.settings.order == "desc") data = data.reverse();

      data.forEach((d, i) => { // put overall at the first position
        if (d.id === "overall") {
          data.splice(i, 1);
          data.unshift(d);
        }
      });

      if (this.settings.limit) {
        const rows = data.slice(0, this.settings.limit);
        if (this.settings.others_grouping) {
          rows.push({ id: "others", name: this.$t("general.others") });
          this.setOthersRow(data.slice(this.settings.limit));
        }
        data = rows;
      }

      return data;
    },
    paramsKey() { // params for the endpoints
      if (this.settings.row_data == "segments") {
        if (this.template.based_on == "companies") return "company_ids";
        return "group_ids";
      }
      return this.kpiKeys[this.settings.row_data];
    },
    kpiKey() { // key to access property/group id from the props
      if (this.settings.row_data == "segments") {
        if (this.template.based_on == "companies") return "company_id";
        return "group_id";
      }
      return this.kpiKeys[this.settings.row_data];
    },
  }
}
</script>

<template>
  <div>
    <table class="w-100 m-0 table table-sm" v-if="!loading && filteredRows && filteredRows.length">
      <thead>
        <tr>
          <th class="text-center" v-if="settings.show_ranking" rowspan="2">{{ $t("reports.ranking") }}</th>
          <th class="text-left align-middle" style="min-width: 180px;" rowspan="2">{{ rowLabel }}</th>
          <template v-for="(col, i) in cols" :key="`header_${i}`">
            <th v-for="l in col.labels" class="text-center" :key="l.key" :colspan="l.subLabels ? l.subLabels.length : 1">{{ l.label }}</th>
          </template>
        </tr>
        <tr>
          <template v-for="(col, i) in cols" :key="`header_${i}`">
            <template v-for="l in col.labels" :key="l.key" >
              <template v-if="l.subLabels && l.subLabels.length > 1">
                <th v-for="sl in l.subLabels" :key="sl.label" class="text-center">{{ sl.label }}</th>
              </template>
            </template>
          </template>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(row, i) in filteredRows" :key="row.id">
          <td class="text-center" v-if="settings.show_ranking">{{ i + 1 }}</td>
          <td>{{ row.name }}</td>

          <template v-for="(cData,i) in columns(row)" :key="cData">
            <template v-for="l in cols[i].labels" :key="`${l.key}_${i}`">
              <!-- groupings -->
              <template v-if="l.subLabels && l.subLabels.length">
                <template v-for="sl in l.subLabels" :key="sl.key">
                  <td class="text-center" :class="sl.coloured && ratingClass(value(cData.values, l.key, sl.key))">
                    {{ value(cData.values, l.key, sl.key) }}
                  </td>
                </template>
              </template>

              <!-- No data -->
              <template v-else-if="!cData || !cData.column">
                <td class="text-center">-</td>
              </template>

              <!-- Ratings -->
              <template v-else-if="/_rating/.test(cData.column.type) && !/trend_|benchmark_/.test(l.key)">
                <!-- Coloured background -->
                <td v-if="cData.column.rating_layout == 'coloured_background'" class="text-center" :class="ratingClass(value(cData.values[l.key]))">
                  {{ value(cData.values[l.key]) }}
                </td>

                <!-- Highlight ratings -->
                <td
                  v-if="cData.column.rating_layout == 'highlight'"
                  class="text-center"
                  :class="highlightClass(cData.values[l.key], cData.column.highlight_from)"
                  :style="`--h-color:${cData.column.highlight_color};--h-font-color:${cData.column.highlight_font_color};`"
                >
                  {{ value(cData.values[l.key]) }}
                </td>

                <!-- Only number -->
                <td v-if="cData.column.rating_layout == 'only_number'" class="text-center">
                  <span class="name"> {{ value(cData.values[l.key]) }} </span>
                </td>

                <!-- Rating bars -->
                <td v-if="cData.column.rating_layout == 'bars'" class="text-center">
                  <div class="data-grid" style="min-width: 90px;">
                    <span class="name"> {{ value(cData.values[l.key]) }} </span>
                    <span class="progress">
                      <span class="progress-bar" :style="`width: ${cData.values[l.key] * 10}%`"></span>
                    </span>
                  </div>
                </td>
              </template>

              <!-- Sentiment count -->
              <template v-else-if="cData.column.type == 'sentiment_count'">
                <td class="text-center" style="vertical-align:middle;">
                  <span v-if="!cData.values[l.key]">{{ "-" }}</span>
                  <div v-else class="d-flex align-items-center" style="min-width: 200px;">
                    <template v-for="pol in polarities" :key="pol">
                      <div v-if="cData.values[l.key][pol] && cData.values[l.key][pol] != 0" :class="`progress-${pol}`" :style="`width: ${cData.values[l.key][pol]}%`"></div>
                    </template>
                  </div>
                </td>
              </template>

              <!-- Trend -->
              <template v-else-if="/^trend_/.test(l.key)">
                <td class="text-center">
                  <template v-if="cData.values[l.key]">
                    <span v-if="cData.column.trend_type == 'percentage'" :class="trendColor(cData.values[l.key].trend)">
                      {{  cData.values[l.key].trend ? cData.values[l.key].trend + "%" : "-" }}
                    </span>
                    <span v-if="cData.column.trend_type == 'value'">
                      {{ cData.values[l.key].prev || "-" }}
                    </span>
                    <span v-if="cData.column.trend_type == 'both'">
                      {{ cData.values[l.key].prev || "-" }}&nbsp;
                      <span :class="trendColor(cData.values[l.key].trend)">
                        {{ cData.values[l.key].trend ? cData.values[l.key].trend + "%" : "-" }}
                      </span>
                    </span>
                  </template>
                  <template v-else>-</template>
                </td>
              </template>

              <!-- Others (including Benchmark) -->
              <template v-else>
                <td class="text-center">
                  {{ value(cData.values, l.key) }}{{ cData.column.type == "response_rate" ? "%" : null }}
                </td>
              </template>
            </template>
          </template>
        </tr>
      </tbody>
    </table>

    <NotLoaded :data="filteredRows" :error="error" v-else-if="!loading" />
    <Loading v-else-if="loading" />
  </div>
</template>
