<script>
import { defineComponent, onMounted, ref, computed, watch, reactive} from 'vue';
import { useRoute } from 'vue-router';
import axios from 'axios';

export default defineComponent({
  name: 'PlanSelection',
  props: {
    handleAcceptedTerms: {
      type: Function,
      required: true,
    },
    handleSelectedPlans: {
      type: Function,
      required: true,
    },
    handleSelectedProduct: {
      type: Function,
      required: true,
    },
    handleSelectedProductOpts: {
      type: Function,
      required: false,
    },
    handleSelectedTerm: {
      type: Function,
      required: false,
    },
  },
  setup(props) {
    const route = useRoute();
    const subscriptionProductSlug = ref('');
    const plans = ref([]);
    const terms = ref([{ label: 'month' }, { label: 'year' }]);
    const selectedTerm = ref(terms.value[0]);
    const loading = ref(true);
    const selectedPlans = ref([]);
    const selectedProductOptions = ref([]);
    const subscriptionProduct = ref({});

    const switchState = reactive({
      isSecondTerm: selectedTerm.value === terms.value[1],
    });

    watch(
      () => switchState.isSecondTerm,
      (newValue) => {
        selectedTerm.value = newValue ? terms.value[1] : terms.value[0];
      }
    );

    watch(
      () => selectedTerm.value,
      (newValue) => {
        switchState.isSecondTerm = newValue === terms.value[1];
      }
    );

    const toggleTerm = () => {
      switchState.isSecondTerm = !switchState.isSecondTerm;
      selectedTerm.value = switchState.isSecondTerm ? terms.value[1] : terms.value[0];
    };

    const getSubscriptionPlans = async () => {
      try {
        const response = await axios.get('/v3/subscription_products/', { params: { slug: subscriptionProductSlug.value } });
        subscriptionProduct.value = response.data[0];
        props.handleSelectedProduct(subscriptionProduct.value);
        plans.value = response.data[0].specs.features.list;
        terms.value = response.data[0].specs.terms;

        if (plans.value.length === 1) {
          selectedPlans.value = [plans.value[0].slug];
        }
        setProductOpts();
        loading.value = false;
      } catch (error) {
        props.handleSelectedProduct({});
        loading.value = false;
      }
    };

    const handleTermChange = (event) => {
      selectedTerm.value = terms.value.find(term => term.label === event.target.value);
      props.handleSelectedTerm(selectedTerm.value);
    };

    const termsAccepted = ref(false);
    onMounted(async () => {
      subscriptionProductSlug.value = route.query.sps;
      await getSubscriptionPlans();
    });

    const setProductOpts = () => {
      if (subscriptionProduct.value.specs.options && subscriptionProduct.value.specs.options.list.length > 0) {
        const is_full_data = subscriptionProduct.value.specs.options.list.find(option => option.name === 'full_data');
        if (is_full_data) {
          selectedProductOptions.value.push({
            name: 'full_data', label: 'Full Data', type: 'checkbox', required: true, multiple: false, selected_value: true
          });
        }

        subscriptionProduct.value.specs.options.list.forEach(option => {
          if (option.name === 'company_id') {
            selectedProductOptions.value.push({
              name: 'company_id', label: 'Company ID', type: 'text', required: true, multiple: false, selected_value: [route.query.spo]
            });
          }
        })
        props.handleSelectedProductOpts(selectedProductOptions.value);
      }
    }

    const total = computed(() => {
      return selectedPlans.value.reduce((acc, slug) => {
        const plan = plans.value.find(plan => plan.slug === slug);
        return acc + (plan ? plan.price[selectedTerm.value.label] : 0);
      }, 0);
    });

    const discounts = computed(() => {
      const discountList = subscriptionProduct.value.specs.discounts.list;
      return discountList.map(discount => {
        const initialPrice = total.value;
        const finalPrice = initialPrice * (100 - discount.percent) / 100;
        if(discount.term !== selectedTerm.value.label) {
          return {
            ...discount,
            initialPrice,
            finalPrice: initialPrice,
          };
        }
        return {
          ...discount,
          initialPrice,
          finalPrice,
        };
      });
    });

    const selectedPlanDetails = computed(() => {
      const selectedDetails = plans.value.filter(plan => selectedPlans.value.includes(plan.slug));
      props.handleSelectedPlans(selectedDetails);
      return selectedDetails;
    });

    return {
      plans,
      loading,
      selectedPlans,
      selectedPlanDetails,
      total,
      discounts,
      termsAccepted,
      subscriptionProduct,
      terms,
      selectedTerm,
      toggleTerm,
      handleTermChange,
      isSecondTerm: switchState.isSecondTerm,
    };
  },
});
</script>

<template>
  <b-container v-if="!loading">
    <b-row class="d-flex justify-content-center text-center">
      <b-col cols="auto">
        <h1>{{ subscriptionProduct.name }}</h1>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center">
      <b-col cols="auto">
        <span>{{ $t('subscription_products_descriptions.' + subscriptionProduct.slug) }}</span>
      </b-col>
    </b-row>

    <b-row class="d-flex justify-content-center">
      <b-col cols="12" md="6" class="d-flex justify-content-center align-items-center">
        <b-card>
          <b-card-text>
            <div v-if="terms.length === 2" class="d-flex justify-content-center align-items-center">
              <span :class="{ 'text-primary': selectedTerm === terms[0] }" class="me-2">
                {{ terms[0].label }}
              </span>
              <b-form-checkbox
                v-model="isSecondTerm"
                switch
                @change="toggleTerm"
                class="term-switch custom-switch"
              ></b-form-checkbox>
              <span :class="{ 'text-primary': selectedTerm === terms[1] }" class="ms-2">
                {{ terms[1].label }}
              </span>
            </div>
            <div v-else>
              <div class="d-flex align-items-center justify-content-between radio-row form-check">
                <label v-for="term in terms" :key="term.label" class="mx-2">
                  <input
                    type="radio"
                    name="terms"
                    :value="term.label"
                    :checked="selectedTerm === term.label"
                    @change="handleTermChange"
                    class="form-check-input"
                  />
                  {{ term.label }}
                </label>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>


    <b-row v-if="plans.length > 1">
      <b-col v-for="feature in plans" :key="feature.slug" class="mb-4" cols="12 col-md-4">
        <div
          class="custom-card-plan"
          :class="{ 'highlighted-card': selectedPlans.includes(feature.slug) }"
        >
          <div class="custom-card-plan-body">
            <h5 class="custom-card-plan-title">{{ feature.label }}</h5>
            <p class="custom-card-plan-text">
              {{ feature.price[selectedTerm.label] }} €/{{ selectedTerm.label }}
            </p>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                role="switch"
                :id="feature.slug"
                :name="feature.slug"
                :value="feature.slug"
                v-model="selectedPlans"
              />
              <label class="form-check-label" :for="feature.slug"></label>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col>
        <div class="d-flex justify-content-center text-center custom-card-plan">
          <b-card-title>{{ plans[0].label }}</b-card-title>
          <b-card-text>{{ plans[0].price }} {{ $t('signup.signup_to_paid.euro_month') }}</b-card-text>
        </div>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center mt-4">
      <h2>{{ $t('signup.signup_to_paid.summary') }}</h2>
      <b-col cols="auto" class="d-flex justify-content-center text-center">
        <b-card>
          <b-card-text>
            <div v-for="feature in selectedPlanDetails" :key="feature.slug">
              {{ feature.label }}: {{ feature.price[selectedTerm.label] }} €/{{ selectedTerm.label }}
            </div>
            <div>
              {{ $t('signup.signup_to_paid.total') }}: {{ total }} €/{{ selectedTerm.label }}
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="d-flex justify-content-center text-center mt-4">
      <b-col cols="auto" class="d-flex justify-content-center text-center">
        <b-card>
          <b-card-title>{{ $t('signup.signup_to_paid.discounts_applied') }}</b-card-title>
          <b-card-text>
            <div v-for="discount in discounts.filter(d => d.term === selectedTerm.label)" :key="discount.period.label">
              <div v-if="discount.term === selectedTerm.label">
                {{ discount.period.label }}: {{ discount.percent }}% off -
                <span class="initial-price">{{ discount.initialPrice.toFixed(2) }}</span>
                <span class="final-price">&nbsp;{{ discount.finalPrice.toFixed(2) }}&nbsp;</span>
                <span>€/{{ selectedTerm.label }}</span>
              </div>
              <div v-else>
                <span class="final-price">&nbsp;{{ discount.finalPrice.toFixed(2) }}&nbsp;</span>
                <span>€/{{ selectedTerm.label }}</span>
              </div>
            </div>
          </b-card-text>
        </b-card>
      </b-col>
    </b-row>
    <b-row class="mt-4">
      <b-col class="d-flex justify-content-center text-center">
        <object v-if="subscriptionProduct.toc_pdf_url" :data="subscriptionProduct.toc_pdf_url" type="application/pdf"
          width="100%" height="500px">
          <p><a :href="subscriptionProduct.toc_pdf_url">{{ $t('signup.signup_to_paid.terms_and_conditions_pdf') }}.</a>
          </p>
        </object>
      </b-col>
    </b-row>
    <b-row class="mt-2">
      <b-col class="d-flex justify-content-center text-center">
        <b-form-checkbox v-model="termsAccepted" @change="handleAcceptedTerms(termsAccepted)">{{
          $t('signup.signup_to_paid.accept_terms') }}</b-form-checkbox>
      </b-col>
    </b-row>
  </b-container>
  <b-container v-else>
    <b-row>
      <b-col>
        <b-spinner label="Loading..."></b-spinner>
      </b-col>
    </b-row>
  </b-container>
</template>

<style lang="scss">
.initial-price {
  text-decoration: line-through;
  color: red;
}

.final-price {
  font-weight: 500;
  font-size: 1.1em;
}

.term-switch {
  margin-top: 6px !important;
  &.form-check-input:not(:checked) {
    background-color: var(--bs-primary);
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgba(255, 255, 255, 1)'/></svg>") !important;
  }
}

.custom-card-plan {
  border: 1px solid #dee2e6;
  border-radius: 0.375rem;
  margin-bottom: 1rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  transition: transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out, border 0.2s ease-in-out;
}

.custom-card-plan:hover {
    transform: scale(1.02);
    transition: transform 0.2s ease-in-out;
}

.custom-card-plan-body {
  padding: 1.25rem;
  text-align: center;
}

.custom-card-plan-title {
  margin-bottom: 0.75rem;
  font-weight: bold;
}

.custom-card-plan-text {
  margin-bottom: 1rem;
}

.highlighted-card {
  border: 2px solid #007bff;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
  transform: scale(1.05);
}

.form-check-input {
  float: none;
  margin-left: 0;
}
.form-check-label{
    margin-left: 0.5em;
}

</style>
