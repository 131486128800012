<script>
import "gridstack/dist/gridstack.min.css";
import "gridstack/dist/gridstack-extra.min.css";

import dayjs         from "dayjs";
import Utils         from "@/js/utils";
import { GridStack } from "gridstack";
import Multiselect   from "vue-multiselect";
import { debounce }  from "lodash";

// COMPONENTS
import Page                           from "@/components/reports/page";
import KPI                            from "@/components/simple-kpi";
import DonutKPI                       from "@/components/donut-kpi";
import GenericChart                   from "@/components/reports/generic_chart";
import GenericTable                   from "@/components/reports/generic-table";
import GenericDonutChart              from "@/components/reports/generic-donut-chart";
import Heading                        from "@/components/reports/heading";
import Paragraph                      from "@/components/reports/paragraph";
import Image                          from "@/components/reports/image";
import PageBreak                      from "@/components/reports/page_break";
import Spacer                         from "@/components/reports/spacer";
import TrendTracker                   from "@/components/trend-tracker";

import TableTopProperties                from "@/components/reports/table-top-properties";
import TableTopSegments                  from "@/components/reports/table-top-segments";
import TableTopSegmentsBenchmark         from "@/components/reports/table-top-segments-benchmark";
import TableKpiGroupsRatingBenchmark     from "@/components/reports/table-kpi-groups-ratings-benchmark";
import PieChartReviewsTopSegments        from "@/components/reports/pie-chart-reviews-top-segments";
import ChartGoodAvgBadProperties         from "@/components/reports/chart-good-avg-bad-properties";
import TableGroupRatingsWithBenchmark    from "@/components/reports/table-group-ratings-with-benchmark";
import TableKpiGroupsReviewsBenchmark    from "@/components/reports/table-kpi-groups-reviews-benchmark";
import TableKpiGroupsResponseScore       from "@/components/reports/table-kpi-groups-response-score";
import TableResponseScoreByPropertyType  from "@/components/reports/table-response-score-by-property-type";
import TablePropertyTypeDistributions    from "@/components/reports/table-property-type-distributions";
import BarMostReviewsPeriod              from "@/components/reports/bar-most-reviews-period";
import CustomHtml                        from "@/components/reports/html";
import TableBenchmarkOwnGroups           from "@/components/reports/table-benchmark-own-groups";
import TableSurveyScores                 from "@/components/reports/table-survey-scores";
import TableSourceRatings                from "@/components/source-ratings";
import SegmentByPeriod                   from "@/components/reports/segment-by-period";

export default{
  data () {
    return {
      cellsPerLine: 12,
      grids:        null,
      groups:       {},
      companies:    {},
      groupId:      null,
      groupIds:     [],
      companyId:    null,
      start_date:   null,
      end_date:     null,
      pageNumber:   0,
      render:       true,
      items:        [],
      textAligns:   { undefined: 'start', 'left': 'start', 'center': 'center', 'right': 'end' },
      cssGlobalStatements: ["@import", "@font-face", "@keyframes", "@media", "@supports", "@page", "@namespace", "@document"]
    }
  },
  props: ["contract", "dt", "template", "componentsList", "editor", "settingsModalOpen", "selectorClass", "editedComponent", "isValidCss"],
  components: { Multiselect, Page, KPI, DonutKPI, GenericChart, GenericTable, GenericDonutChart, Heading, Paragraph, Image, PageBreak, Spacer, TrendTracker, TableTopProperties, TableTopSegments, TableTopSegmentsBenchmark, TableKpiGroupsResponseScore, TableResponseScoreByPropertyType, TableKpiGroupsRatingBenchmark, TableKpiGroupsReviewsBenchmark, PieChartReviewsTopSegments, ChartGoodAvgBadProperties, TableGroupRatingsWithBenchmark, TablePropertyTypeDistributions, CustomHtml, TableBenchmarkOwnGroups, TableSurveyScores, TableSourceRatings, BarMostReviewsPeriod, SegmentByPeriod },
  mounted() {
    if (!this.dt.components) this.dt.components = [];
    this.items = [...this.dt.components];
    if (this.items.length == 0 || this.items[this.items.length-1].name != 'page_break') {
      const index     = this.items.length ? Math.max(...this.items.map( it => it.id)) + 1 : 0;
      this.items.push({ id: index, name: 'page_break', settings: {} })
    }

    this.groupIds   = this.setGroupIds()
    this.groupId    = this.setGroupId()
    this.companyId = this.$route.query.company_id

    this.loadReportParams();
    this.$nextTick(() => { this.loadGridStack(); });
    this.setReportStyles();
    this.observeContainerSize();
  },
  methods: {
    childUpdate() {
      this.grids?.forEach(g => {
        if (!g.engine) return
        g.engine.nodes.forEach(n => {
          if (!n.el.getElementsByClassName("grid-stack-item-content")[0]?.children?.length) return
          g.resizeToContent(n.el)
        })
      })
      this.$nextTick(() => this.setPageHeights());
    },
    updateURL(field) {
      let query = {};
      if (field == "groups") {
        query.group_id  = [this.groupId];
        query.group_ids = this.groupIds?.length ? this.groupIds : undefined;
      } else {
        query.company_id = [this.companyId];
      }
      this.$router.push({ path: this.$route.path, query: { ...this.$route.query, ...query } });
    },
    setGroupId () {
      return this.$route.query.group_id || this.groupIds[0]
    },
    setGroupIds () {
      if (!this.$route.query.group_ids) return []
      if (typeof this.$route.query.group_ids == 'string')
        return this.$route.query.group_ids.split(',')
      return this.$route.query.group_ids
    },
    loadGridStack() {
      this.$nextTick(() => {
        this.grids = GridStack.initAll(this.gridOptions);

        this.setupDrag();
        this.grids.forEach(grid => {
          this.setGSCallbacks(grid)
          this.updateFromNodes(grid);
        })
      })
    },
    setGSCallbacks(grid) {
      grid.on("added", this.added);
      grid.on("change", this.changed);
      grid.on("removed", this.removed);
    },
    setPageHeights() {
      setTimeout(() => {
        const elements = document.getElementsByClassName("page-frame");
        const pageHeight = 1465;
        let h;
        for(const el of elements) {
          el.style.height = 'auto';
          if (this.editor)
            h = pageHeight
          else
            h = (parseInt(el.offsetHeight / pageHeight) + 1) * pageHeight;
          el.style.height = `${h}px`;
        }
      }, 500);
    },
    changed (event, elements) {
      this.$nextTick(() => {
        elements.forEach(el => {
          const id = Number(el.id)
          let item = this.items.find((i) => i.id == id)
          if (!item) return //console.error(`Cant find this widget with id ${el.id}`)
          item.layout = { x: el.x, y: el.y, w: el.w, h: el.h }
        });
        this.updateComponents()
        // this.printNodes()
      })
    },
    async added(ev, items) {
      for (const i of items) {
        if (!i.el.dataset.id) return; // makeWidget triggers events

        const component = this.componentsList[i.el.dataset.id];
        const index     = this.items.length ? Math.max(...this.items.map( it => it.id)) + 1 : 0;
        const item      = { id: index, name: i.el.dataset.id, layout: {x: i.x, y: i.y, w: component.minW, h: component.minH} };
        this.addToItemsAtPosition(item, i.grid)
        this.updateGrid(item, i)
        // add new grid
        this.$nextTick(() => {
          document.getElementsByClassName('grid-stack').forEach((el,i) => {
            if (!el.gridstack) {
              const grid = GridStack.addGrid(el, this.gridOptions)
              this.setGSCallbacks(grid)
              this.grids.splice(i, 0, grid) //add to our list
            }
          });
          //this.updateComponents();
        })
        this.$emit("openSettingsModal", item);
      }
    },
    removed () {
      this.$nextTick(() => { this.updateComponents(); })
    },
    updateComponents() {
      this.dt.components = this.grids.map((grid, i) => {
        const list = this.updateFromNodes(grid)
        if (this.splitItems[i].break) list.push(this.splitItems[i].break)
        return list
      }).flat()
    },
    addToItemsAtPosition(item, grid) {
      const gridIndex = this.grids.findIndex( g => g.el == grid.el)
      let pos         = this.pageBreakIndexes()[gridIndex]
      if (item.name == 'page_break') pos++
      this.items.splice(pos, 0, item)
    },
    updateGrid (item, i) {
      this.$nextTick(() => {
        i.grid.removeWidget(i.el, true, false);
        if (item.name != 'page_break') i.grid.makeWidget(`#${item.id}`);
      })
    },
    setupDrag() {
      GridStack.setupDragIn(".new-widget", { appendTo: "body", helper: this.cloneItem, handle: ".grid-stack-item" });
    },
    cloneItem(event) {
      return event.target.cloneNode(true);
    },
    regenerateComponents() {
      console.error('regenerateComponents was called. Replace it with updateComponents');
      this.grids.forEach(grid => { this.updateFromNodes(grid) })
      this.dt.components = this.splitItems.map(page => page.lines.sort((a,b) => a.layout.y - b.layout.y || a.layout.x - b.layout.x)).flat()
    },
    // called to sync items with new positions from grid
    updateFromNodes(grid) {
      let items = []
      for (const node of grid.engine.nodes) {
        const i = this.items.find(i => i.id == Number(node.id))
        if (i) {
          items.push(i)
          i.layout.x = node.x
          i.layout.y = node.y
          i.layout.w = node.w
          i.layout.h = node.h
        }
      }
      return items
    },
    removeItem(id, dontAsk) {
      if (!dontAsk && !window.confirm(this.$t("reports.confirm_removing"))) return;
      const elIndex = this.items.findIndex((item) => item.id == id);
      const item    = this.items[elIndex]
      if (elIndex < 0) return console.error("cant find the widget");
      const gridIndex = this.pageBreakIndexes().findIndex(i => i >= elIndex )
      const grid      = this.grids[gridIndex]
      const gridIds   = grid.engine.nodes.map(n => n.id)

      if (item.name == 'page_break') {
        grid.removeAll(false)
        grid.destroy(false)
        this.grids.splice(gridIndex, 1)
      }
      else
        grid.removeWidget(document.getElementById(id), true, true);

      this.items.splice(elIndex, 1);
      const pbIndex = this.items.findLastIndex(i => i.name == 'page_break')
      if (this.items.length > pbIndex + 1) { // last pb is not at end
      // Move last page_break to end of array
        this.items.push(this.items.splice(pbIndex, 1)[0])
      }

      this.$nextTick(() => {
        // add widgets to grid above after removing the old grid
        if (item.name == 'page_break') gridIds.forEach(n => this.grids[gridIndex -1].makeWidget(`#${n}`) )
        this.$nextTick(() => {
          this.updateComponents();
        })
      })
      //this.printNodes()
    },
    printNodes() {
      this.grids.forEach((grid,i) => {
        console.log('grid', i);
        for (const node of grid.engine.nodes) {
          console.log(node);
        }
      })
    },
    pageBreakIndexes () {
      if (this.items.length == 0) return []
      let indexes = this.items.reduce(function(a, e, i) {
        if (e.name == 'page_break') a.push(i);
        return a;
      }, [])
      if (this.items[this.items.length - 1].name != 'page_break')
        indexes.push(this.items.length)
      return indexes
    },
    gridStackAttributes(i) {
      const c = this.componentsList[i.name];
      return {
        "id":      i.id,
        "gs-id":   i.id,
        "gs-x":    i.layout.x,
        "gs-y":    i.layout.y,
        "gs-w":    i.layout.w,
        "gs-h":    i.layout.h,
        "gs-min-w": c.minW,
        "gs-min-h": c.minH,
      };
    },
    async rerender() {
      this.render = false;
      this.$nextTick(() => {
        this.render = true;
      });
    },
    loadReportParams() {
      if (this.template.based_on == "groups")    this.loadGroups();
      if (this.template.based_on == "companies") this.loadCompanies();

      let period      = this.template.period == 'bimonth' ? 'month' : this.template.period
      this.end_date   = this.$route.query.end_date || dayjs().startOf(period).add(-1, "day").format("YYYY-MM-DD");
      if (this.$route.query.start_date)
        this.start_date = this.$route.query.start_date
      else {
        this.start_date = dayjs(this.end_date).startOf(period)
        if (this.template.period == 'bimonth') this.start_date = this.start_date.add(-1, 'month')
        this.start_date = this.start_date.format("YYYY-MM-DD");
      }
    },
    loadCompanies () {
      return this.$store.dispatch("companies/fetch", { contract_id: this.contract.id }).then(companies => {
        this.companies   = Utils.groupByUniq(companies, "id");
        this.companyId ??= companies[0].id;
        this.updateURL("companies");
      });
    },
    loadGroups () {
      return this.$store.dispatch("groups/fetch", { contract_id: this.contract.id }).then(groups => {
        this.groups = Utils.groupByUniq(groups, "id");
        this.groupId ??= Object.keys(this.groups)[0];
        this.updateURL("groups");
      });
    },
    setReportStyles() {
      const reportContainer = this.$refs?.["reports-container"];
      const styles          = this.dt.styles;
      if (!reportContainer) return;

      if (styles.title_font_size)   reportContainer.style.setProperty("--title-font-size", styles.title_font_size + "px");
      if (styles.use_primary_color) {
        if (styles.primary_color) reportContainer.style.setProperty("--bs-primary", styles.primary_color);
        if (styles.font_color)    reportContainer.style.setProperty("--sidebar-text", styles.font_color);
      }
      if (styles.general) this.setCustomCssStyles(reportContainer, styles.general);
    },
    setCustomCssStyles: debounce(function (container, cssString = "") {
      if (!container || !cssString) return;

      let styleElement = document.querySelector("#custom-styles");
      if (!styleElement) styleElement = document.createElement("style");
      styleElement.setAttribute("id", "custom-styles");

      const css = cssString.replaceAll(/body|html/g, ".report-styled");
      const lines = css.split("\n");
      const processedLines = [];
      lines.forEach(line => {
        const trimmedLine = line.trim();
        const isGlobalStatement = !!this.cssGlobalStatements.find(r => line.match(r));
        if (trimmedLine.endsWith("{") && !isGlobalStatement) {
          let selectors = trimmedLine.split(",");
          selectors = selectors.map(s => {
            if (!s.includes(".report-styled")) return `.report-styled ${s}`;
            return s;
          }).join(", ");
          processedLines.push(selectors);
          return;
        } else if (trimmedLine.endsWith("}")) {
          processedLines.push(line);
          return;
        }
        processedLines.push(line);
      });
      const processedCSS = processedLines.join("\n");
      if (this.editor) {
        this.isValidCss(cssString, processedCSS);
      }
      styleElement.textContent = processedCSS;
      container.insertBefore(styleElement, container.firstChild);
    }, 300),
    observeContainerSize() {
      const templateContainer = this.$refs?.["reports-container"];
      if (!templateContainer || !this.editor) return;

      const resizeObserver = new ResizeObserver(() => {
        const pageFrames = [...templateContainer.querySelectorAll(".page-frame")];
        const zoomFactor = templateContainer.offsetWidth / pageFrames[0].scrollWidth;
        pageFrames.forEach(p => {
          p.style.zoom = `${zoomFactor*100}%`;
        });
      });

      resizeObserver.observe(templateContainer);
    }
  },
  computed: {
    gridOptions () {
      return {
        column: this.cellsPerLine,
        layout: "list",
        minRow: 1,
        cellHeight: "1px",
        sizeToContent: !this.editor,
        margin: 0,
        acceptWidgets: function() { return true }
      }
    },
    propertyNames() {
      if (this.template.based_on == "groups") return this.groups[this.groupId]?.name
      return this.companies[Number(this.companyId)]?.name
    },
    splitItems() {
      let pages = [];
      let page  = { lines: [], break: null }
      for (const item of this.items) {
        if (item.name == "page_break") {
          page.break = item;
          pages.push(page);
          page = { lines: [], break: null }
        } else {
          page.lines.push(item);
        }
      }
      if (!pages.length || page.lines.length) pages.push(page)
      return pages;
    },
    pageBreaks() {
      return this.items.filter(i => i.name == 'page_break')
    },
    allGroupIds () {
      return Object.keys(this.groups || {});
    },
    allCompanyIds () {
      return Object.keys(this.companies || {});
    },
    groupName () {
      return this.groups[Number(this.groupId)]?.name
    },
    companyName () {
      return this.companies[Number(this.companyId)]?.name
    },
    renderWidget() {
      return (this.companyId || this.groupId?.length) && this.start_date && this.render
    }
  },
  watch: {
    groupId: {
      handler: function () {
        if (this.groupId) {
          this.rerender();
        }
      },
      immediate: true
    },
    groupIds: {
      handler: function () {
        if (this.groupIds?.length) {
          this.rerender();
        }
      },
      immediate: true
    },
    companyId: {
      handler: function () {
        if (this.companyId?.length) {
          this.rerender();
        }
      },
      immediate: true
    },
    "dt.styles": {
      handler: function () {
        if (Object.keys(this.dt?.styles || {}).length) this.setReportStyles();
      },
      deep: true
    }
  }
}
</script>

<template>
  <div :class="!editor && 'reports report-template-container'">
    <template v-if="groups && template.based_on == 'groups'">
      <div class="form-group hide-print overflow-visible" :class="selectorClass">
        <label class="form-label">{{ $t("reports.main_group_label") }}</label>
        <multiselect :multiple="false" v-model="groupId" :options="allGroupIds" :custom-label="id => groups[id] && groups[id].name" :showLabels=false :placeholder="$t('filter.select_option')" @select="updateURL('groups')" @remove="updateURL('groups')"></multiselect>
      </div>

      <div class="form-group hide-print overflow-visible" :class="selectorClass">
        <label class="form-label">{{ $t("reports.benchmark_group_label") }}</label>
        <multiselect :multiple="true" v-model="groupIds" :options="allGroupIds" :custom-label="id => groups[id] && groups[id].name" :showLabels=false :placeholder="$t('filter.select_option')" @select="updateURL('groups')" @remove="updateURL('groups')"></multiselect>
      </div>
    </template>

    <div class="form-group hide-print overflow-visible" :class="selectorClass" v-if="companies && template.based_on == 'companies'">
      <label class="col-2 form-label">{{ $t("reports.companies_to_test") }}</label>
      <multiselect class="col-6 w-50" v-model="companyId" :options="allCompanyIds" :custom-label="id => companies[id]?.name" :showLabels=false :placeholder="$t('filter.select_option')" @select="updateURL('companies')" @remove="updateURL('companies')"></multiselect>
    </div>

    <div class="report-styled" :class="editor && 'editor report-template-container overflow-auto p-3 rounded overflow-visible'" ref="reports-container">
      <template v-for="(page, index) in splitItems" :key="index" >
        <Page
          :template="template"
          :contract="contract"
          :period="{ start_date, end_date }"
          :propertyNames="propertyNames"
          :pageBreak='pageBreaks[index]'
        >
          <div class="page-layout-dropdown" v-if="editor && pageBreaks[index]">
            <div class="dropdown">
              <button class="btn page-layout-icon dropdown-toggle" data-bs-toggle="dropdown" :title="$t('reports.set_page_layout')" aria-expanded="false" ></button>
              <div class="dropdown-menu">
                <li><label class='dropdown-item-text'>{{ $t('reports.page_layout.label') }}</label></li>
                <div class="dropdown-divider"></div>
                <div class="form-group d-flex justify-content-start" v-for='opt in ["hide_title", "hide_header", "hide_footer"]' :key='opt'>
                  <input type='checkbox' v-model="pageBreaks[index].settings[opt]" class='ms-2' style='width: 20px;' :id='`cb-${index}-${opt}`' />
                  <label :for='`cb-${index}-${opt}`' class="ms-2">{{ $t(`reports.page_layout.${opt}`) }}</label>
                </div>
              </div>
            </div>
          </div>
          <div class="grid-stack h-full w-full">
            <div class="grid-stack-item" v-bind="gridStackAttributes(c)" v-for="c in page.lines" :key="c.id">
              <div class="grid-stack-item-content" :style='`min-height: ${c.id == "spacer" ? "1px" : "20px"}`'>
                <div v-if="renderWidget && c.settings && editedComponent != c">
                  <h5 v-if="c.settings && c.settings.show_title" class='w-100' :class="`text-${textAligns[c.settings.title_alignment]}`">{{ c.settings.title }}</h5>

                  <template v-if="editor">
                    <a href="javascript:void(0);" class="edit-icon link-warning" @click="removeItem(c.id)" :title="$t('reports.remove_component')"></a>
                    <a href="javascript:void(0);" class="settings-icon" @click="$emit('openSettingsModal', c)" :title="$t('reports.settings')" v-if="componentsList[c.name] && componentsList[c.name].settingsFields.keys.length"></a>
                  </template>

                  <component
                    :is="componentsList[c.name].component"
                    :edition="editor"
                    :settings="c.settings"
                    :contract="contract"
                    :start_date="start_date"
                    :end_date="end_date"
                    :group_id="groupId"
                    :group_ids="groupIds"
                    :company_id="companyId"
                    :group_name='groupName'
                    :company_name='companyName'
                    :template='template'
                    v-if='Object.keys(c.settings || {}).length && componentsList[c.name] && (companyId || groupId) && (c.name != "html" || (template.based_on != "groups" || groupName))'
                    @vue:updated='childUpdate'
                  />
                  <template v-else>
                    <div v-if='!Object.keys(c.settings || {}).length'></div>
										<div v-else-if='!componentsList[c.name]'>Component not found.</div>
									</template>
                </div>
              </div>
            </div>
          </div>
        </Page>
        <div class="position-relative page-break-container">
          <a href="javascript:void(0);" class="edit-icon link-warning" @click="removeItem(page.break.id)" :title="$t('reports.remove_component')"></a>
          <component
            :is="componentsList[page.break?.name].component"
            :edition="editor"
            :settings="page.break.settings"
            :contract="contract"
            :start_date="start_date"
            :end_date="end_date"
            :group_id="groupId"
            :group_ids="groupIds"
            :company_id="companyId"
            :group_name='groupName'
            :company_name='companyName'
            :template='template'
            v-if='editor && componentsList[page.break?.name] && (companyId || groupId)'
            @vue:updated='childUpdate'
          />
        </div>
      </template>
    </div>
  </div>
</template>
