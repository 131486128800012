<script>
import Reports   from '@/js/reports';
import NotLoaded from "@/components/not-loaded";
import Loading   from "@/components/loading";
import Utils     from "@/js/utils";

export default {
  data() {
    return {
      loading: true,
      error: false,
      pieSize: "65%",
      pieTop: "50%",
      chartHeight: 300,
      chartData: [],
    }
  },
  props: ['group_ids', 'start_date', 'end_date', 'group_id', 'settings', 'company_id'],
  components: { NotLoaded, Loading },
  methods: {
    ...Reports.requests,
    async load() {
      try {
        this.error = false;
        this.loading = true;
        this.settings.bench_group_ids = this.group_ids.slice(this.group_ids.length - 2)
        let chartData = await this.getReports('top_segments_benchmark')

        chartData    = chartData.slice(1).map(d => { return { value: d[4], name: this.translateSegment(this.settings.segment, d[1], d[0]) } })
        const others = 1 - chartData.reduce((s, d) => { return s += d.value }, 0)
        if (others > 0) chartData.push({ value: others, name: this.$t('reports.others') })

        this.chartData = chartData
      } catch {
        this.error = true;
        this.loading = false;
      }
    }
  },
  computed: {
    chartOptions() {
      return {
        labels: this.chartData.map(c => `${c.name}: ${Utils.round(c.value * 100, 1)}%`),
        colors: ["#a3cae0", "#e74c5e", "#47bd9a", "#964b00", "#4090cb", "#f9d570", "#2a8251", "#f408a9", "#26e6ee", "#ffa500", "#0841ab", "#02610d"], // 12 colors
        tooltip: { y: { formatter: val => Utils.round(val * 100, 1) + "%" } },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
          verticalAlign: 'middle',
          floating: false,
          offsetX: 0,
          offsetY: -10
        },
        responsive: [{
          breakpoint: 600,
          options: {
            chart:  { height: 240 },
            legend: { show: false },
          }
        }]
      }
    }
  },
  mounted() {
    this.load()
  }
}
</script>
<template>
  <div :style="`height:${this.settings.height || 300}px;`">
    <apexchart v-if="chartData && chartData.length" class="apex-charts" height="100%" type="pie" dir="ltr" :series="chartData.map(c => c.value)" :options="chartOptions"></apexchart>

    <NotLoaded :data="chartData" :error="error" v-else-if="!loading" />
    <Loading v-else-if="loading" />
  </div>
</template>
