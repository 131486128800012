<script>
import Utils           from "@/js/utils";
import ReportUtils     from "@/js/reports";
import Multiselect     from "vue-multiselect";
import { ColorPicker } from "vue3-colorpicker";
import { Field }       from "vee-validate";

export default {
  data() {
    return {};
  },
  props: ["template", "dt", "showDesignSection", "cssValidation"],
  components: { Multiselect, ColorPicker, Field },
  created() {
    if (this.dt && (Array.isArray(this.dt.styles) || !this.dt.styles)) this.dt.styles = {};
  },
  methods: {
    ...Utils.date,
    validateCss() {
      return !this.cssValidation.length;
    }
  },
  computed: {
    periods() {
      return this.allPeriods();
    },
    basedOnOptions() {
      return ReportUtils.templates.basedOn;
    },
    isValidCss() {
      return !this.cssValidation.length;
    }
  }
}
</script>

<template>
  <div>
    <!-- Name field -->
    <div class="form-group mb-3">
      <label class="form-label">{{ $t("reports.title") }}</label>
      <input type="text" class="form-control" :class="{ valid: template.name.length, invalid: !template.name.length }" :placeholder="$t('reports.template_name_help')" v-model="template.name" :state="false" />
    </div>

    <!-- Based on -->
    <div class="form-group mt-3">
      <label class="form-label">{{ $t("reports.based_on") }}</label><br>
      <multiselect :multiple="false" v-model="template.based_on" :options="basedOnOptions" allowEmpty :custom-label="b => $t(`reports.based_on_opts.${b}`)" :showLabels="false" :placeholder="$t('filter.select_option')" ></multiselect>
    </div>

    <!-- Period -->
    <div class="form-group my-3">
      <label class="form-label">{{ $t("reports.period") }}</label><br>
      <template v-for="p in Object.keys(periods)" :key="p">
        <div class="custom-control custom-control-inline custom-radio b-custom-control-md">
          <input type="radio" name="period" v-model="template.period" :value="p" class="custom-control-input" :id="`period_${p}`" />
          <label class="custom-control-label" :for="`period_${p}`">{{ $t(`general.${p}`) }}</label>
        </div>
      </template>
    </div>

    <!-- Design section -->
    <template v-if="showDesignSection">
      <h5 class="mb-4 mt-5">{{ $t("new_survey.settings.design") }}</h5>

      <div class="form-group row mb-4 align-items-center">
        <label class="form-label col-3 m-0">{{ $t("reports.title_font_size") }}</label>
        <div class="col-2">
          <div class="input-group">
            <input type="number" class="form-control" max="40" v-model="dt.styles.title_font_size" />
            <span class="input-group-text">px</span>
          </div>
        </div>
      </div>

      <div class="form-group row mb-4 align-items-center">
        <label class="col-3 form-label m-0">{{ $t("reports.use_custom_primary_color") }}</label>
        <div class="col-1 d-flex align-items-center">
          <input type="checkbox" v-model="dt.styles.use_primary_color" class="form-check-input m-0"/>
        </div>
      </div>

      <div class="form-group row align-items-center" v-if="dt.styles.use_primary_color">
        <label class="col-3 form-label m-0">{{ $t("reports.primary_color") }}</label>
        <div class="col-5 d-flex align-items-center justify-content-start">
          <div class="d-flex align-items-center me-5">
            <label class="form-label m-0 me-3">{{ $t("reports.background") }}</label>
            <ColorPicker v-model:pureColor="dt.styles.primary_color" useType="pure" format="hex" pickerType="chrome" lang="En" />
          </div>

          <div class="d-flex align-items-center">
            <label class="form-label m-0 me-3">{{ $t("reports.font") }}</label>
            <ColorPicker v-model:pureColor="dt.styles.font_color" useType="pure" format="hex" pickerType="chrome" lang="En" />
          </div>
        </div>
      </div>

      <div class="form-group row mb-4 align-items-center">
        <label class="form-label col-3 m-0">{{ $t("reports.logo_url") }}</label>
        <div class="col-9">
          <Field rules="url" :name="$t('reports.logo_url')" v-slot="{ field, errors }" v-model="dt.styles.logo_url">
            <input type="url" v-bind="field" class="form-control m-0"/>
            <span class="text-danger font-size-12" id="error">{{ errors[0] }}</span>
          </Field>
        </div>
      </div>

      <div class="d-flex flex-column mb-2">
        <label class="form-label">{{ $t("reports.custom_styles") }}</label>
        <Field :rules="validateCss" :name="$t('reports.custom_styles')" v-slot="{ field }" v-model="dt.styles.general">
          <textarea class="form-control custom-textarea" rows="10" v-bind="field" :class="{ valid: isValidCss, invalid: !isValidCss }"></textarea>
          <span class="text-danger font-size-12" id="error">
            <template v-for="(e, i) in cssValidation" :key="`${i}_${e.evidence}`">
              {{ `${i+1}: ${e.message}: ${e.evidence}` }}<br>
            </template>
          </span>
        </Field>
      </div>
    </template>
  </div>
</template>
