<script>
import PageHeader      from "@/components/page-header";
import Modal           from "@/components/modal-users-reports";
import Filters         from "@/components/filter";
import DataUtils       from "@/js/data-utils";
import axios           from "axios";
import Utils           from "@/js/utils";
import ReputationUtils from "@/js/reputation-utils";
import Swal            from "sweetalert2";
import OleryTable      from "@/components/olery-table";

export default {
  data() {
    return {
      user: null,
      user_id: null,
      users: {},
      myData: { assignments: { groups: [], companies: [] } },
      filterCols: ["companies", 'search', 'report_templates', 'periods'],
      filterParams: {},
      schedules: {},
      periods: {
        day:     this.$t('reports.periods.day'),
        week:    this.$t('reports.periods.week'),
        month:   this.$t('reports.periods.month'),
        quarter: this.$t('reports.periods.quarter'),
        year:    this.$t('reports.periods.year')
      },
      perPage: 50,
      currentPage: 1,
      schedulesCount: 0,
      loading: true,
      modals: {
        schedule_report: false,
        edit_schedule:   false
      },
      reportTemplates: {},
      editedSchedule: {}
    };
  },
  components: { PageHeader, Filters, Modal, OleryTable },
  watch: {
    contract: {
      handler: function () {
        if (this.contract?.id) this.load();
      },
      immediate: true
    }
  },
  async created() {
    this.user = await this.$store.dispatch("user/fetch");
    let query = this.$route.query;
    if (query.uid || query.user_id) this.user_id = Number(query.uid || query.user_id);
  },
  methods: {
    ...DataUtils,
    ...ReputationUtils,
    async load() {
      if (this.users[this.contract.id]) return;
      Utils.setLoading.bind(this)(true);
      if (!this.user) this.user = await this.$store.dispatch("user/fetch");
      this.users[this.contract.id] = await this.$store.dispatch("users/fetch", { contract_id: this.contract.id });
      let me = this.users[this.contract.id].find(u => u.id == this.user?.id);
      if (!me && this.user.manager) me = await this.loadManagerPermissions();
      this.myData = Utils.deepClone(me);

      await this.loadSchedules();
      await this.loadReportTemplates();
      if (this.user_id) this.scheduleFromUserId();
      Utils.setLoading.bind(this)(false);
    },
    async loadSchedules() {
      if (!this.schedules[this.contract.id]) {
        let response = await axios.get("/v3/reports/schedules", { params: { subscription_ids: this.contract.id } });
        this.schedules[this.contract.id] = response.data.data;
        this.schedulesCount = response.data.data.length;
      }
    },
    async openModal(item) {
      if (!item) this.modals.schedule_report = true;
      else this.modals.edit_schedule = true;
      this.editedSchedule = item;
    },
    async loadReportTemplates() {
      if (this.reportTemplates[this.contract.id]) return;
      this.reportTemplates[this.contract.id] = await this.$store.dispatch("reportTemplates/fetch", { contract_id: this.contract.id });
    },
    async removeSchedule(item) {
      let response = await Swal.fire({
        title: this.$t("user_management.users.alert.are_you_sure"),
        text:  this.$t("reports.delete_schedule_question"),
        icon: "question",
        showCancelButton:  true,
        showConfirmButton: true,
        allowOutsideClick: false
      });
      if (!response.isConfirmed) return;
      axios.delete(`/v2/report-schedules/${item.id}`, { params: {
        company_ids: item.companies.map(c => c.id),
        group_ids:   item.groups.map(g => g.id)
      }})
      let index = this.schedules[this.contract.id].findIndex(s => s.id == item.id)
      this.schedules[this.contract.id].splice(index, 1)
    },
    scheduleFromUserId() {
      let user = this.users[this.contract.id].find(u => u.id == this.user_id);
      this.openModal({ assignments: [], companies: [], groups: [], template: {}, user });
    },
    goToPage(to) {
      if (to == "previous")  this.currentPage--;
      else if (to == "next") this.currentPage++;
      else if (to)           this.currentPage = to;
      this.$refs["ot_report_schedules"]?.$refs["report-schedules"]?.updatePage(this.currentPage);
    }
  },
  computed: {
    contract() {
      return this.$store.getters["contract/currentContract"];
    },
    columns() {
      return [
        { value: "user.name",     text: this.$t('reports.name'),       sortable: true },
        { value: "user.email",    text: this.$t('reports.email'),      sortable: true },
        { value: "template.name", text: this.$t('reports.template'),   sortable: true },
        { value: "period",        text: this.$t('reports.period'),     sortable: true },
        { value: "properties",    text: this.$t('reports.properties'), sortable: true },
        { value: "template_overrides", text: this.$t('reports.template_overrides'), sortable: false },
        { value: "actions",       text: this.$t('reports.actions'),    sortable: true },
      ]
    },
    filteredSchedules() {
      if (!this.contract?.id || !this.schedules[this.contract.id]) return [];
      let p = this.filterParams;
      if (!p.data && !p.periods && !p.report_templates) return this.schedules[this.contract.id];

      let schedules = [...this.schedules[this.contract.id]];

      if (p.periods?.length) schedules = schedules.filter(s => p.periods.includes(s.period));

      if (p.data.search?.length) {
        let str = p.data.search.toUpperCase();
        schedules = schedules.filter(s => {
          let nameEmail = (s.user.name || "").toUpperCase() + '|' + s.user.email.toUpperCase();
          return nameEmail.includes(str);
        })
      }

      if (p.report_templates?.length) schedules = schedules.filter(s => p.report_templates.includes(s.template.id));

      if (p.data?.company_ids?.length) schedules = schedules.filter(s => s.companies.find(c => p.data.company_ids.includes(c.id)));

      if (schedules.length != this.schedules[this.contract.id].length) this.goToPage(1);
      return schedules;
    },
    userData() {
      const schedule = this.editedSchedule;
      const userPermissions = this.users[this.contract.id].find(u => u.id == schedule.user.id)?.assignments;
      const groups = schedule.groups.map(g => userPermissions.groups.find(up => up.id == g.id)).filter(n => n);
      return { ...schedule.user, full_name: schedule.user.name, assignments: { companies: schedule.companies, groups: groups }, options: userPermissions, report_template_id: schedule.template.id };
    }
  }
}
</script>

<template>
  <div class="w-100">
    <PageHeader :title="$t('reports.report_schedules')" :tooltip="$t('reports.schedules_help')">
      <template v-slot:subtitle>
        <button class="btn btn-primary" @click="openModal()">{{ $t("reports.new_schedule") }}</button>
      </template>
    </PageHeader>

    <Filters @filter:created="saveFilterParams" @filter:change="saveFilterParams" :cols='filterCols' :notifyChange='true' />

    <div class="row">
      <div class="col-xl-12">
        <div style='min-height: 200px;' class="card" :class="{ loading }">
          <div class="card-body">
            <OleryTable
              v-if="!loading"
              ref="ot_report_schedules"
              :fileName="$t('reports.report_schedules')"
              :btnText="$t('general.export_excel')"
              btnClass="btn btn-secondary text-nowrap"
              :exportCols="columns.slice(0, -1)"
              :dataTable="{
                tableHeaderClass:    'thead-light',
                items:               filteredSchedules,
                headers:             columns,
                headerTextDirection: 'center',
                bodyTextDirection:   'center',
                pagination:          true
              }"
            >
              <template #item-period="{ item }">
                {{ this.periods[item.period] || item.period }}
              </template>

              <template #item-properties="{ item }">
                <template v-if="item.groups && item.groups.length">
                  <strong class="text-darken-blue">{{ $t("reports.groups") }}:&nbsp;</strong>
                  <span>{{ item.groups.map(g => g.name).join(", ") }}</span>
                  <br>
                </template>
                <template v-if="item.companies && item.companies.length">
                  <strong class="text-darken-blue">{{ $t("reports.companies") }}:&nbsp;</strong>
                  <span>{{ item.companies.map(c => c.name).join(", ") }}</span>
                </template>
              </template>

              <template #item-template_overrides="{ item }">
                <template v-if="!Object.keys(item.template_overrides || {}).length">-</template>
                <template v-if="item.template_overrides.start_months_ago">
                  <strong class="text-darken-blue">{{ $t("reports.start_months_ago") }}:&nbsp;</strong>
                  <span>{{ item.template_overrides.start_months_ago }}</span>
                  <br>
                </template>
                <template v-if="item.template_overrides.end_months_ago">
                  <strong class="text-darken-blue">{{ $t("reports.end_months_ago") }}:&nbsp;</strong>
                  <span>{{ item.template_overrides.end_months_ago }}</span>
                </template>
              </template>

              <template #item-actions="{ item }">
                <a href='#' @click='openModal(item)' class='text-nowrap me-3' >
                  <i class="mdi mdi-square-edit-outline"></i>
                  {{ $t('reports.edit_report_schedule') }}
                </a>
                <a href='#' @click='removeSchedule(item)' class='text-nowrap me-3' >
                  <i class="mdi mdi-trash-can"></i>
                  {{ $t('reports.remove_report_schedule') }}
                </a>
              </template>
            </OleryTable>
          </div>
        </div>
      </div>
    </div>

    <b-modal v-model="modals.edit_schedule" centered hide-footer hide-header size="lg">
      <Modal v-if="modals.edit_schedule" :edition="true" context="report_schedule" :contract="contract" :modals="modals" :title="$t('reports.edit_report_schedule_title')" type="schedule_report" modalId="edit_schedule" :userData="userData" :myData="myData" :reportTemplates="reportTemplates[contract.id]" :reportSchedules="schedules[contract.id]" :reportSchedule="editedSchedule" />
    </b-modal>

    <b-modal v-model="modals.schedule_report" centered hide-footer hide-header size="lg">
      <Modal v-if="modals.schedule_report" :edition="false" context="report_schedule" :contract="contract" :modals="modals" :title="$t('reports.add_report_schedule_title')" type="schedule_report" modalId="schedule_report" :reportTemplates="reportTemplates[contract.id]" :myData="myData" :reportSchedules="schedules[contract.id]" :users="users[contract.id]" />
    </b-modal>
  </div>
</template>
